var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { showError } from 'store/commonReducer/common.action';
import { delayInMilliSeconds, PAGINATION_LIMIT, TOAST_MESSAGES, } from './../../globals/global.constants';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { removeObjectProperty, getCareAgentInfo } from 'globals/global.functions';
import { convertEndDateInUTCTz, convertStartDateInUTCTz, convertUTCSecondsToUnixNanoSeconds, getClientTimezone, } from 'globals/date.functions';
import { getAllCareInsightEventsService, updateInsightStatusService, } from 'services/careInsightService/insightSummary.service';
import { CareInsightStatus, CareInsightTypes, ToastMessageType, EventViewState, } from './../../globals/enums';
import { getCareInsightHistory } from './../../pages/WCPages/SeniorCareInsights/SeniorCareInsight.action';
import { createAlertDialog } from './Alerts.action';
import { push } from 'redux-first-history';
import { getAdminCareInsight, resetCareInsightReviewData, } from 'pages/WCPages/Admin/CareInsightReview/CareInsightReview.action';
import { adminBaseRoute, appRoutesEndpoints } from 'routes/appRoutesEndpoints';
import { getAllAlarmEventsService } from 'services/alarmService/alarm.service';
import { createAlarmDialog } from '../alarmReducer/Alarm.action';
import { createCallEntryDialog } from 'pages/WCPages/SeniorDashboard/components/CallEntry/CallEntry.action';
export var ADD_SUMMARY = 'ADD_SUMMARY';
export var CREATE_SUMMARY = 'CREATE_SUMMARY';
export var UPDATE_FACILITY_SUMMARY_LIST = 'UPDATE_FACILITY_SUMMARY_LIST';
export var MINIMIZE_EVENT = 'MINIMIZE_EVENT';
export var MAXIMIZE_EVENT = 'MAXIMIZE_EVENT';
export var CLOSE_EVENT = 'CLOSE_EVENT';
export var CREATE_ALERT = 'CREATE_ALERT';
export var CREATE_MILESTONE = 'CREATE_MILESTONE';
export var REMOVE_ALL_EVENTS = 'REMOVE_ALL_EVENTS';
/**
 * @description action creator to minimize the events
 */
export var minimizeEvent = function (eventId, eventType, data) { return function (dispatch) {
    dispatch({ type: MINIMIZE_EVENT, payload: { eventId: eventId, eventType: eventType, data: data } });
}; };
/**
 * @function maximizeEvent
 * @description action creator to maximize the events
 */
export var maximizeEvent = function (eventId, eventType) { return function (dispatch) {
    dispatch({ type: MAXIMIZE_EVENT, payload: { eventId: eventId, eventType: eventType } });
}; };
/**
 * @function removeAllEvents
 * @description action creator to remove all the events
 */
export var removeAllEvents = function () { return function (dispatch) {
    dispatch({ type: REMOVE_ALL_EVENTS });
}; };
/**
 * @function closeEvent
 * @description action creator to close the events
 */
export var closeEvent = function (eventId, eventType) {
    return function (dispatch, getState) {
        var events = getState().events;
        var updatedEvent = removeObjectProperty(events[eventType], eventId);
        dispatch({ type: CLOSE_EVENT, payload: { eventType: eventType, updatedEvent: updatedEvent } });
    };
};
/**
 * @function getAlertCareInsightHistory
 * @description action creator to get care insight history
 * @param {*} startDate
 * @param {*} endDate
 * @param {string} accountId
 * @param {string} seniorId
 * @returns alert history api response
 */
export var getAlertCareInsightHistory = function (startDate, endDate, accountId, seniorId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var clientTimezone, payload, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clientTimezone = getClientTimezone();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    payload = {
                        account_id: accountId,
                        senior_id: seniorId,
                        start_timestamp: convertUTCSecondsToUnixNanoSeconds(convertStartDateInUTCTz(startDate, clientTimezone)),
                        end_timestamp: convertUTCSecondsToUnixNanoSeconds(convertEndDateInUTCTz(endDate, clientTimezone)),
                        desc: true,
                        insight_type: CareInsightTypes.Action,
                        // status: CareInsightStatus.New,
                    };
                    return [4 /*yield*/, dispatch(getEventsInsightHistory(payload))];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_1 = _a.sent();
                    // eslint-disable-next-line no-console
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @description action creator to get events insight history
 * @param {IGetInsightsPayload} payload
 * @returns
 */
export var getEventsInsightHistory = function (payload) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, dispatch(getCareInsightHistory(payload))];
            case 1:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, response];
            case 2:
                error_2 = _a.sent();
                dispatch(hideApplicationLoader());
                if (error_2 instanceof Error) {
                    dispatch(showToast(error_2.message, ToastMessageType.Error));
                }
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getAllCareInsightEvents
 * @description action creator to get the existing new events(actions, sos, fall detection)
 * @returns void
 */
export var getAllCareInsightEvents = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var statusNewRequest, statusAbandonedRequest, response, _i, response_1, careInsightData, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                statusNewRequest = getAllCareInsightEventsService({
                    status: CareInsightStatus.New,
                    insight_type: CareInsightTypes.Action,
                });
                statusAbandonedRequest = getAllCareInsightEventsService({
                    status: CareInsightStatus.Abandoned,
                    insight_type: CareInsightTypes.Action,
                });
                return [4 /*yield*/, Promise.all([statusNewRequest, statusAbandonedRequest])];
            case 1:
                response = _a.sent();
                response = response.flat();
                for (_i = 0, response_1 = response; _i < response_1.length; _i++) {
                    careInsightData = response_1[_i];
                    if (careInsightData.type === CareInsightTypes.Action) {
                        dispatch(createAlertDialog(careInsightData));
                    }
                }
                return [3 /*break*/, 3];
            case 2:
                error_3 = _a.sent();
                dispatch(showToast(TOAST_MESSAGES.GET_ALL_CARE_INSIGHT.FAIL, ToastMessageType.Error));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getAllAlarmEvents
 * @description action creator to get the existing new events(actions, sos, fall detection)
 * @returns void
 */
export var getAllAlarmEvents = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userInfo, wait, pending, assigned, _i, pending_1, alarmData, _a, assigned_1, callData, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                userInfo = getCareAgentInfo();
                wait = function (ms) { return new Promise(function (resolve) { return setTimeout(resolve, ms); }); };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 13, , 14]);
                return [4 /*yield*/, getAllAlarmEventsService({
                        statuses: JSON.stringify(['pending']),
                    })];
            case 2:
                pending = _b.sent();
                return [4 /*yield*/, getAllAlarmEventsService({
                        statuses: JSON.stringify(['assigned']),
                        user_id: userInfo.userId,
                    })];
            case 3:
                assigned = _b.sent();
                _i = 0, pending_1 = pending;
                _b.label = 4;
            case 4:
                if (!(_i < pending_1.length)) return [3 /*break*/, 8];
                alarmData = pending_1[_i];
                return [4 /*yield*/, dispatch(createAlarmDialog(alarmData))];
            case 5:
                _b.sent();
                return [4 /*yield*/, wait(delayInMilliSeconds.SOS_FALL)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                _i++;
                return [3 /*break*/, 4];
            case 8:
                _a = 0, assigned_1 = assigned;
                _b.label = 9;
            case 9:
                if (!(_a < assigned_1.length)) return [3 /*break*/, 12];
                callData = assigned_1[_a];
                return [4 /*yield*/, dispatch(createCallEntryDialog(callData))];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11:
                _a++;
                return [3 /*break*/, 9];
            case 12: return [3 /*break*/, 14];
            case 13:
                error_4 = _b.sent();
                console.log(error_4);
                dispatch(showToast(TOAST_MESSAGES.GET_ALL_CARE_INSIGHT.FAIL, ToastMessageType.Error));
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to update insight status
 * @param {ICareInsightUpdateStatusPayload} payload
 * @returns void
 */
export var updateInsightStatus = function (payload) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                return [4 /*yield*/, updateInsightStatusService(payload)];
            case 1:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, response];
            case 2:
                error_5 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_5));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to change the careinsight status to no action
 * @param {string} careInsightId
 * @returns void
 */
export var updateNoActionStatus = function (careInsightId) { return function (dispatch) {
    var careAgentInfo = getCareAgentInfo();
    var payload = {
        care_insight_ids: careInsightId,
        updated_by: careAgentInfo.userName.first_name,
        status: CareInsightStatus.NoAction,
    };
    dispatch(updateInsightStatus(payload));
}; };
//temp action creator need to remove in future
export var tempNoActionAllAlert = function () { return function (dispatch, getState) {
    var allAlerts = getState().events.alert;
    var arrayAlertsId = Object.values(allAlerts).map(function (value) { return value.alertId; });
    dispatch(updateNoActionStatus(arrayAlertsId));
    dispatch(removeAllEvents());
}; };
/**
 * @description action creator to redirect the user on CI review page when clicking approve dialog
 * @function redirectApproveDialog
 */
export var redirectApproveDialog = function () { return function (dispatch, getState) {
    var currentPath = getState().router.location.pathname;
    var limit = PAGINATION_LIMIT.adminCareInsightReview;
    if (currentPath ===
        appRoutesEndpoints.admin.nestedRoutes.careInsightReview.baseRoute) {
        dispatch(resetCareInsightReviewData());
        dispatch(getAdminCareInsight(0, limit));
        dispatch(closeAlertAndSummaryNotifications());
    }
    else {
        dispatch(closeAlertAndSummaryNotifications());
        dispatch(push("".concat(adminBaseRoute).concat(appRoutesEndpoints.admin.nestedRoutes.careInsightReview.baseRoute)));
    }
}; };
/**
 * @description action creator to close the approve dialog
 * @function closeAllApproveDialog
 */
export var closeAllApproveDialog = function () { return function (dispatch, getState) {
    var alert = Object.values(getState().events.alert);
    var summary = Object.values(getState().events.summary);
    var milestone = Object.values(getState().events.milestone);
    var allEvent = __spreadArray(__spreadArray(__spreadArray([], summary, true), alert, true), milestone, true);
    allEvent.forEach(function (element) {
        if (element.viewState === EventViewState.Approve) {
            dispatch(closeEvent(element.eventId, element.eventType));
        }
    });
    Object.values(alert).some(function (value) {
        return value.viewState === EventViewState.Approve;
    });
}; };
/**
 * @description action creator to close the alert and summary approve dialog
 * @function closeAlertAndSummaryNotifications
 */
export var closeAlertAndSummaryNotifications = function () { return function (dispatch, getState) {
    var alert = Object.values(getState().events.alert);
    var summary = Object.values(getState().events.summary);
    var allEvent = __spreadArray(__spreadArray([], summary, true), alert, true);
    allEvent.forEach(function (element) {
        if (element.viewState === EventViewState.Approve) {
            dispatch(closeEvent(element.eventId, element.eventType));
        }
    });
    Object.values(alert).some(function (value) {
        return value.viewState === EventViewState.Approve;
    });
}; };
/**
 * @description action creator to close the milestone notification
 * @function closeMilestoneNotification
 */
export var closeMilestoneNotification = function () { return function (dispatch, getState) {
    var milestone = Object.values(getState().events.milestone);
    milestone.forEach(function (element) {
        if (element.viewState === EventViewState.Approve) {
            dispatch(closeEvent(element.eventId, element.eventType));
        }
    });
}; };
/**
 * @description action creator to close all actions notification
 * @function closeAllActionNotification
 */
export var closeAllActionNotification = function () { return function (dispatch, getState) {
    var alert = Object.values(getState().events.alert);
    var summary = Object.values(getState().events.summary);
    var allEvent = __spreadArray(__spreadArray([], summary, true), alert, true);
    allEvent.forEach(function (element) {
        if (element.viewState === EventViewState.ActionNotification) {
            dispatch(closeEvent(element.eventId, element.eventType));
        }
    });
    Object.values(alert).some(function (value) {
        return value.viewState === EventViewState.ActionNotification;
    });
}; };
/**
 * @description action to add facility summary
 * @function createFacilitySummary
 */
export var createFacilitySummary = function (facilitySummaryData) {
    return function (dispatch, getState) {
        var facilitySummary = getState().events.facilitySummary || [];
        dispatch({
            type: UPDATE_FACILITY_SUMMARY_LIST,
            payload: __spreadArray(__spreadArray([], facilitySummary, true), [facilitySummaryData], false),
        });
    };
};
/**
 * @description action to remove facility summary
 * @function removeFacilitySummary
 */
export var removeFacilitySummary = function (careInsightIds) { return function (dispatch, getState) {
    var facilitySummary = getState().events.facilitySummary || [];
    var newFacilitySummary = facilitySummary.filter(function (data) { return !careInsightIds.includes(data === null || data === void 0 ? void 0 : data.careInsightId); });
    dispatch({
        type: UPDATE_FACILITY_SUMMARY_LIST,
        payload: newFacilitySummary,
    });
}; };
