var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment-timezone';
var SleepCycle = function (_a) {
    var graphData = _a.graphData;
    var theme = useTheme();
    var _b = useState(), dataPoints = _b[0], setDataPoints = _b[1];
    var _c = useState({}), shownTooltips = _c[0], setShownTooltips = _c[1];
    var _d = useState(''), sleepPhaseDuration = _d[0], setSleepPhaseDuration = _d[1];
    useEffect(function () {
        setDataPoints(createGraph(graphData));
    }, [graphData]);
    var createGraph = function (data) {
        var sleepPhasesRawData = data;
        var phases = [
            {
                name: 'awake',
                count: 1,
                height: 88,
            },
            {
                name: 'light',
                count: 1,
                height: 36,
            },
            {
                name: 'deep',
                count: 1,
                height: 16,
            },
            {
                name: 'rem',
                count: 1,
                height: 64,
            },
            {
                name: 'no_reading',
                count: 1,
                height: 88,
            },
            {
                name: 'cycle_break',
                count: 1,
                height: 0,
            },
        ];
        var sleepPhases = {};
        var allSleepPhasesObj = {};
        Object.entries(sleepPhasesRawData).forEach(function (_a, index) {
            var key = _a[0], value = _a[1];
            var sleepCycle = value.measurement;
            if (index > 0) {
                //Subtracting 1 minute from start time
                var cycleBreakTime = key - (1 * 60 * 1000) / 0.000001;
                //Adding Cycle Break
                allSleepPhasesObj[cycleBreakTime] = {
                    time: parseInt(cycleBreakTime),
                    phase: 5,
                };
            }
            sleepCycle.forEach(function (sleepPhaseData) {
                sleepPhaseData.measurements.forEach(function (measurement) {
                    allSleepPhasesObj[measurement.time] = measurement;
                });
            });
        });
        var sleepPhasesObj = {};
        var currentPhaseKey = null;
        var currentPhase = null;
        var previousPhase = null;
        var allSleepPhasesArray = Object.keys(allSleepPhasesObj);
        var sleepStartTimestamp = parseInt(allSleepPhasesArray[0]);
        var sleepEndTimestamp = parseInt(allSleepPhasesArray[allSleepPhasesArray.length - 1]);
        var startTime = moment(sleepStartTimestamp * 0.000001);
        var endTime = moment(sleepEndTimestamp * 0.000001);
        while (endTime.diff(startTime, 'minutes') >= 0) {
            var timestamp = (startTime.valueOf() * 1000000).toString();
            var currentSleepReading = null;
            if (timestamp in allSleepPhasesObj) {
                currentSleepReading = allSleepPhasesObj[timestamp];
                currentPhase = currentSleepReading.phase;
            }
            else {
                currentPhase = 4;
                currentSleepReading = {
                    time: parseInt(timestamp),
                    phase: 4,
                };
            }
            if (null === previousPhase) {
                previousPhase = currentPhase;
            }
            currentPhaseKey = "".concat(phases[currentPhase].name).concat(phases[currentPhase].count);
            if (currentPhase !== previousPhase) {
                phases[currentPhase].count = phases[currentPhase].count + 1;
                currentPhaseKey = "".concat(phases[currentPhase].name).concat(phases[currentPhase].count);
            }
            if (sleepPhasesObj[currentPhaseKey]) {
                sleepPhasesObj[currentPhaseKey].push(currentSleepReading);
            }
            else {
                sleepPhasesObj[currentPhaseKey] = [currentSleepReading];
            }
            previousPhase = currentPhase;
            startTime.add(1, 'minutes');
        }
        var readingX = 0;
        sleepPhases.data = Object.keys(sleepPhasesObj).map(function (key) {
            var sleepPhasesArray = sleepPhasesObj[key];
            var startTimeSleepPhase = sleepPhasesArray[0].time * 0.000001;
            var endTimeSleepPhase = sleepPhasesArray[sleepPhasesArray.length - 1].time * 0.000001;
            var duration = moment.duration(moment(endTimeSleepPhase).diff(moment(startTimeSleepPhase)));
            var sleepPhaseIndex = sleepPhasesArray[0].phase;
            var sleepPhaseObj = {
                sleepPhase: key,
                sleepPhaseIndex: sleepPhaseIndex,
                startTime: startTimeSleepPhase,
                endTime: endTimeSleepPhase,
                readingX: readingX,
                sleepPhaseDuration: duration.asMinutes(),
                barWidth: sleepPhasesArray.length,
                barHeight: phases[sleepPhaseIndex].height,
            };
            readingX = readingX + sleepPhasesArray.length + 1;
            return sleepPhaseObj;
        });
        var sleepStartBarIndex = 0;
        sleepPhases.data.find(function (sleepPhase, index) {
            sleepStartBarIndex = index;
            return sleepPhase.sleepPhaseIndex === 0;
        });
        var sleepEndBarIndex = 0;
        var lastSleepPhase = sleepPhases.data
            .slice()
            .reverse()
            .find(function (sleepPhase, index) {
            sleepEndBarIndex = index;
            return (sleepPhase.sleepPhaseIndex !== 0 && sleepPhase.sleepPhaseIndex !== 4);
        });
        sleepPhases.sleepStartBarIndex = sleepStartBarIndex;
        var lastIndex = sleepPhases.data.length - 1;
        sleepPhases.sleepEndBarIndex = lastIndex - sleepEndBarIndex;
        var startSleepPhase = sleepPhases.data.find(function (sleepPhase) { return sleepPhase.sleepPhaseIndex !== 0; });
        var sleepStart = startSleepPhase === null || startSleepPhase === void 0 ? void 0 : startSleepPhase.startTime;
        sleepPhases.sleepStart = moment(sleepStart).format('hh:mm A');
        var sleepEnd = lastSleepPhase === null || lastSleepPhase === void 0 ? void 0 : lastSleepPhase.endTime;
        sleepPhases.sleepEnd = moment(sleepEnd).format('hh:mm A');
        var diffDuration = moment.duration(moment(sleepEnd).diff(moment(sleepStart)));
        var hours = Math.floor(diffDuration.asHours());
        var mins = Math.floor(diffDuration.asMinutes()) - hours * 60;
        sleepPhases.durationTotal = "".concat(hours, "h").concat(mins, "m");
        sleepPhases.durationInMins = hours * 60 + mins;
        return sleepPhases;
    };
    var toggleTooltip = function (id, sleepStart, sleepEnd) {
        setShownTooltips(function (prevShownTooltips) {
            var _a;
            return (_a = {},
                _a[id] = !prevShownTooltips[id],
                _a);
        });
        setSleepPhaseDuration(getSleepPhaseDuration(sleepStart, sleepEnd));
    };
    var getSleepPhaseDuration = function (sleepStart, sleepEnd) {
        return "".concat(moment(sleepStart).format('hh:mm A'), " - ").concat(moment(sleepEnd).format('hh:mm A'));
    };
    var commonColors = {
        0: theme.palette.customColor.sleepGrey,
        1: theme.palette.customColor.remGreen,
        2: theme.palette.customColor.moderateGreen,
        3: theme.palette.customColor.intenseGreen,
    };
    var sleepPhaseColors = __assign(__assign({}, commonColors), { default: theme.palette.customColor.sleepGrey });
    var sleepPhaseLabels = {
        1: 'LIGHT',
        2: 'DEEP',
        3: 'REM',
        4: 'INTERUPPTION',
        default: 'AWAKE',
    };
    var colors = __assign(__assign({}, commonColors), { 4: function (index, data) {
            var _a;
            return ((_a = data[index + 1]) === null || _a === void 0 ? void 0 : _a.sleepPhaseIndex) == 5
                ? theme.palette.common.white
                : theme.palette.customColor.sleepGrey;
        } });
    return (_jsxs(Box, { style: {
            flex: 1,
            WebkitUserSelect: 'none',
            userSelect: 'none',
        }, "data-testid": 'sleep-cycle-graph', children: [_jsxs(Box, { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }, children: [_jsx(Box, { style: {
                            color: theme.palette.customColor.info,
                        }, children: dataPoints && dataPoints.sleepStart }), _jsx(Box, { style: {
                            color: theme.palette.customColor.info,
                        }, children: dataPoints && dataPoints.sleepEnd })] }), dataPoints && dataPoints.data.length > 0 && (_jsx(Box, { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingBottom: 8,
                    paddingTop: 57,
                }, children: dataPoints.data.map(function (sleepPhase, index) {
                    var _a;
                    return (_jsx(CustomTooltip, { title: sleepPhase.sleepPhaseIndex === 4 &&
                            ((_a = dataPoints.data[index + 1]) === null || _a === void 0 ? void 0 : _a.sleepPhaseIndex) == 5 ? ('') : (_jsxs(Box, { style: {
                                alignItems: 'center',
                                padding: 8,
                            }, children: [_jsx(Box, { style: {
                                        fontSize: 12,
                                        color: theme.palette.customColor.info,
                                    }, children: sleepPhaseDuration }), _jsxs(Box, { style: {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 6,
                                    }, children: [_jsx(Box, { style: {
                                                backgroundColor: sleepPhaseColors[sleepPhase.sleepPhaseIndex] ||
                                                    sleepPhaseColors.default,
                                                width: 10,
                                                height: 10,
                                                borderRadius: 5,
                                                marginRight: 4,
                                            } }), _jsx(Box, { style: {
                                                fontSize: 12,
                                                color: theme.palette.customColor.info,
                                            }, children: sleepPhaseLabels[sleepPhase.sleepPhaseIndex] ||
                                                sleepPhaseLabels.default })] })] })), placement: 'top', onOpen: function () {
                            toggleTooltip(sleepPhase.sleepPhase, sleepPhase.startTime, sleepPhase.endTime);
                        }, disableFocusListener: sleepPhase.barHeight == 0, disableTouchListener: sleepPhase.barHeight == 0, disableHoverListener: sleepPhase.barHeight == 0, arrow: true, children: _jsx(Box
                        // activeOpacity={1}
                        , { 
                            // activeOpacity={1}
                            style: {
                                width: "".concat((sleepPhase.sleepPhaseDuration * 100) /
                                    dataPoints.durationInMins, "%"),
                            }, children: _jsxs(Box, { style: {
                                    position: 'relative',
                                    zIndex: 999,
                                }, children: [_jsxs(Box, { style: {
                                            height: 100 - sleepPhase.barHeight + 10,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }, children: [_jsx(Box, { style: {
                                                    flex: 1,
                                                    borderRightWidth: shownTooltips[sleepPhase.sleepPhase]
                                                        ? 0.5
                                                        : 0,
                                                } }), _jsx(Box, { style: {
                                                    flex: 1,
                                                    borderLeftWidth: shownTooltips[sleepPhase.sleepPhase]
                                                        ? 0.5
                                                        : 0,
                                                } })] }), _jsx(Box, { style: {
                                            height: sleepPhase.barHeight,
                                            backgroundColor: typeof colors[sleepPhase.sleepPhaseIndex] ===
                                                'function'
                                                ? colors[sleepPhase.sleepPhaseIndex](index, dataPoints.data)
                                                : colors[sleepPhase.sleepPhaseIndex],
                                        } })] }) }) }, "phase".concat(sleepPhase.endTime)));
                }) })), _jsxs(Box, { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }, children: [_jsx(BottomLabel, { color: theme.palette.customColor.sleepGrey, title: 'Awake' }), _jsx(BottomLabel, { color: theme.palette.customColor.remGreen, title: 'Rem' }), _jsx(BottomLabel, { color: theme.palette.customColor.moderateGreen, title: 'Light Sleep' }), _jsx(BottomLabel, { color: theme.palette.customColor.intenseGreen, title: 'Deep Sleep' })] })] }));
};
var BottomLabel = function (_a) {
    var title = _a.title, color = _a.color;
    return (_jsxs(Box, { style: { display: 'flex', flexDirection: 'row' }, children: [_jsx(Box, { style: {
                    backgroundColor: color,
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    marginRight: 4,
                } }), _jsx(Box, { children: title })] }));
};
var CustomTooltip = withStyles(Tooltip, function (theme) { return ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: theme.typography.toolTipFontSize,
        backgroundColor: theme.palette.common.white,
    },
    arrow: {
        '&::before': {
            backgroundColor: theme.palette.common.white,
            border: "1px solid ".concat(theme.palette.customColor.smokeGrey),
        },
    },
}); });
export { SleepCycle };
