var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
import { showError } from 'store/commonReducer/common.action';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { getAllCIRangeMilestonesService } from 'services/cIRangeMilestonesService/cIRangeMilestones.service';
import { CIRangeMilestones } from 'globals/enums';
export var RESET_CI_RANGE_MILESTONES = 'RESET_CI_RANGE_MILESTONES';
export var GET_OPEN_CI_RANGE_MILESTONES_SUCCESS = 'GET_OPEN_CI_RANGE_MILESTONES_SUCCESS';
export var GET_COMPLETED_CI_RANGE_MILESTONES_SUCCESS = 'GET_COMPLETED_CI_RANGE_MILESTONES_SUCCESS';
export var GET_OPEN_CI_RANGE_MILESTONES_FAIL = 'GET_OPEN_CI_RANGE_MILESTONES_FAIL';
export var GET_COMPLETED_CI_RANGE_MILESTONES_FAIL = 'GET_COMPLETED_CI_RANGE_MILESTONES_FAIL';
export var UPDATE_OPEN_CI_RANGE_MILESTONES_PAGE_NUMBER = 'UPDATE_OPEN_CI_RANGE_MILESTONES_PAGE_NUMBER';
export var UPDATE_COMPLETED_CI_RANGE_MILESTONES_PAGE_NUMBER = 'UPDATE_COMPLETED_CI_RANGE_MILESTONES_PAGE_NUMBER';
/**
 * @functions getAdminOpenCIRangeMilestones
 * @description action creator to fetch the open care insight range milestones on admin dashboard
 * @returns void
 */
export var getAdminOpenCIRangeMilestones = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var lastEvaluatedKey, payload, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                lastEvaluatedKey = getState().cIRangeMilestones.open.lastEvaluatedKey;
                payload = {
                    status: CIRangeMilestones.Pending,
                };
                if (lastEvaluatedKey) {
                    payload.last_evaluated_key = lastEvaluatedKey;
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAllCIRangeMilestonesService(payload)];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, {
                        data: response.data,
                        lastEvaluatedKey: response.lastEvaluatedKey,
                    }];
            case 3:
                error_1 = _a.sent();
                dispatch(showError(error_1));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @functions getAdminCompletedCIRangeMilestones
 * @description action creator to fetch the completed care insight range milestones on admin dashboard
 * @returns void
 */
export var getAdminCompletedCIRangeMilestones = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var lastEvaluatedKey, payload, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                lastEvaluatedKey = getState().cIRangeMilestones.completed.lastEvaluatedKey;
                payload = {
                    status: CIRangeMilestones.Completed,
                };
                if (lastEvaluatedKey) {
                    payload.last_evaluated_key = lastEvaluatedKey;
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAllCIRangeMilestonesService(payload)];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, {
                        data: response.data,
                        lastEvaluatedKey: response.lastEvaluatedKey,
                    }];
            case 3:
                error_2 = _a.sent();
                dispatch(showError(error_2));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getOpenCIRangeMilestonesSuccess
 * @description action creator to store open CI Range Milestones table data
 * @param  {ICIRangeMilstoneServicedata} tableData
 */
export var getOpenCIRangeMilestonesSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    var payload = {
        data: data,
        lastEvaluatedKey: lastEvaluatedKey,
    };
    dispatch({
        type: GET_OPEN_CI_RANGE_MILESTONES_SUCCESS,
        payload: payload,
    });
}; };
/**
 * @function getCompletedCIRangeMilestonesSuccess
 * @description action creator to store completed CI Range Milestones table data
 * @param {ICIRangeMilstoneServicedata} tableData
 */
export var getCompletedCIRangeMilestonesSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    var payload = {
        data: data,
        lastEvaluatedKey: lastEvaluatedKey,
    };
    dispatch({
        type: GET_COMPLETED_CI_RANGE_MILESTONES_SUCCESS,
        payload: payload,
    });
}; };
/**
 * @function getOpenCIRangeMilestonesFail
 * @description action creator to fetch error on open api get fail
 * @param error
 */
export var getOpenCIRangeMilestonesFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_OPEN_CI_RANGE_MILESTONES_FAIL,
    });
}; };
/**

 * @function getCompletedCIRangeMilestonesFail
 * @description action creator to fetch error on completed api get fail
 * @param error
 */
export var getCompletedCIRangeMilestonesFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_COMPLETED_CI_RANGE_MILESTONES_FAIL,
    });
}; };
/**
 * @function updateOpenCIRangeMilestonesPageNumber
 * @description action creator to update the page number of open CI Range Milestones table
 * @param {string | number} value
 */
export var updateOpenCIRangeMilestonesPageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_OPEN_CI_RANGE_MILESTONES_PAGE_NUMBER,
        payload: value,
    });
}; };
/**
 * @function updateCompletedCIRangeMilestonesPageNumber
 * @description action creator to update the page number of completed CI Range Milestones table
 * @param {string | number} value
 */
export var updateCompletedCIRangeMilestonesPageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_COMPLETED_CI_RANGE_MILESTONES_PAGE_NUMBER,
        payload: value,
    });
}; };
/**
 * @function resetCIRangeMilestonesData
 * @description action creator to reset all CIRangeMilestones table data
 */
export var resetCIRangeMilestonesData = function () { return function (dispatch) {
    dispatch({ type: RESET_CI_RANGE_MILESTONES });
}; };
