var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box } from '@mui/material';
import { getClientTimezone } from 'globals/date.functions';
import { DASHBOARD_VITALS_TYPE, HEART_RATE_CONDITION, ACTIVITY_CONDITION, WEIGHT_CONDITION, SLEEP_CONDITION, } from 'globals/enums';
import { getCurrentDate, getCurrentSenior, kgToLbs, roundOff, } from 'globals/global.functions';
import { useAppDispatch } from 'hooks/reduxHooks';
import get from 'lodash.get';
import moment from 'moment';
import ResidentDetailsCard from 'pages/FacilityPages/AllResidents/component/ResidentsCard/ResidentDetailsCard.component ';
import { getSleepDaily } from 'pages/WCPages/WellnessDashboard/components/Sleep/sleep.action';
import React, { useState } from 'react';
import { getThresholdService } from 'services/careInsightService/threshold.service';
import { showError } from 'store/commonReducer/common.action';
import GraphAPI from 'utilities/GraphAPI';
import { useQuery } from 'utilities/react-query';
import { getActivityVerbiage, getHeartRateVerbiage, getSleepVerbiage, getWeightVerbiage, } from './WellnessIndicator.utility';
import { makeStyles } from 'tss-react/mui';
var useStyles = makeStyles()(function () { return ({
    container: {
        display: 'flex',
        gap: '30px',
        '& > div': {
            flexGrow: 1,
        },
    },
}); });
var WellnessIndicators = function () {
    var classes = useStyles().classes;
    var _a = useState({
        heartRate: HEART_RATE_CONDITION.NO_DATA,
        activity: ACTIVITY_CONDITION.NO_DATA,
        sleep: SLEEP_CONDITION.NO_DATA,
        weight: WEIGHT_CONDITION.NO_DATA,
    }), conditions = _a[0], setConditions = _a[1];
    var dispatch = useAppDispatch();
    var threshold = useQuery({
        queryKey: ['thresholdData'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var seniorInfo, params, response;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        seniorInfo = getCurrentSenior();
                        params = {
                            account_id: seniorInfo.account_id,
                            senior_ids: JSON.stringify([seniorInfo.senior_id]),
                        };
                        return [4 /*yield*/, getThresholdService(params)];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.threshold) || null];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }).data;
    var currentHeartRate = useQuery({
        queryKey: ['heartRateData'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var currentDate, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentDate = __assign({}, getCurrentDate(getClientTimezone()));
                        return [4 /*yield*/, GraphAPI.fetchHeartExtreme(currentDate.startTime, currentDate.endTime)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, (response === null || response === void 0 ? void 0 : response.last) || ''];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }).data;
    var currentActivityScore = useQuery({
        queryKey: ['activityData'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var currentDate, response, totalModerateDuration, totalIntenseDuration;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentDate = __assign({}, getCurrentDate(getClientTimezone()));
                        return [4 /*yield*/, GraphAPI.fetchActivityScore(currentDate.startTime, currentDate.endTime)];
                    case 1:
                        response = _a.sent();
                        totalModerateDuration = 0;
                        totalIntenseDuration = 0;
                        return [2 /*return*/, Math.floor(response === null || response === void 0 ? void 0 : response.rolling_data.activity_score)];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }).data;
    var weightData = useQuery({
        queryKey: ['weightData'],
        queryFn: function () {
            var currentDate = __assign({}, getCurrentDate(getClientTimezone()));
            return GraphAPI.fetchBodyHealthMeasurement(currentDate.startTime, currentDate.endTime);
        },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }).data;
    var _b = React.useMemo(function () {
        var weight = get(weightData, 'extremities.raw_data.average_weight');
        var lastWeight = get(weightData, 'last.weight');
        return {
            currentWeight: weight ? kgToLbs(weight) : 0,
            lastWeight: lastWeight ? kgToLbs(lastWeight) : 0,
        };
    }, [weightData]), currentWeight = _b.currentWeight, lastWeight = _b.lastWeight;
    var currentSleepScore = useQuery({
        queryKey: ['sleepData'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var currentSleepDate, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentSleepDate = __assign({}, getCurrentDate(getClientTimezone()));
                        return [4 /*yield*/, dispatch(getSleepDaily(currentSleepDate.startTime, currentSleepDate.endTime))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.averageSleepScore || ''];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }).data;
    React.useEffect(function () {
        var newConditions = __assign(__assign(__assign(__assign({}, getHeartRateVerbiage(currentHeartRate, threshold)), getWeightVerbiage(currentWeight, lastWeight)), getSleepVerbiage(currentSleepScore)), getActivityVerbiage(currentActivityScore));
        setConditions(function (conditions) { return (__assign(__assign({}, conditions), newConditions)); });
    }, [
        threshold,
        currentHeartRate,
        currentWeight,
        lastWeight,
        currentSleepScore,
        currentActivityScore,
    ]);
    return (_jsxs(Box, { className: classes.container, children: [_jsx(ResidentDetailsCard, { cardType: DASHBOARD_VITALS_TYPE.WELLNESS, condition: conditions.heartRate, activityTitle: 'Heart Rate', activityValue: currentHeartRate ? "".concat(currentHeartRate, " bpm") : '-', isResident: true }), _jsx(ResidentDetailsCard, { cardType: DASHBOARD_VITALS_TYPE.ACTIVITY, condition: conditions.activity, activityTitle: 'Activity', activityValue: currentActivityScore
                    ? moment()
                        .startOf('day')
                        .add(currentActivityScore, 'minutes')
                        .format('h[h]m[m]')
                    : '-', isResident: true }), _jsx(ResidentDetailsCard, { cardType: DASHBOARD_VITALS_TYPE.SLEEP, condition: conditions.sleep, activityTitle: 'Sleep Score', activityValue: currentSleepScore || '-', isResident: true }), _jsx(ResidentDetailsCard, { cardType: DASHBOARD_VITALS_TYPE.HEALTH, condition: conditions.weight, activityTitle: 'Weight', activityValue: currentWeight ? "".concat(roundOff(currentWeight, 1), " lbs") : '-', isResident: true })] }));
};
export default WellnessIndicators;
