var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import axios from 'axios';
import { getCareAgentInfo, injectStorageEvent, saveUserInfo, } from './global.functions';
import { CACHE_EVENTS_AUTO_LOGOUT, SESSION_EXPIRED_MESSAGE, } from './global.constants';
import { logoutCache } from '../pages/WCPages/Login/Login.action';
import { PUBLIC_APIS } from './apiEndPoints';
var CARE_PORTAL_URL = "".concat((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin, "/api/");
var controller = new AbortController();
var isAlreadyFetchingAccessToken = false;
var subscribers = [];
function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter(function (callback) { return callback(access_token); });
}
function addSubscriber(callback) {
    subscribers.push(callback);
}
function retryRequest(originalRequest) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, new Promise(function (resolve) {
                        addSubscriber(function (access_token) {
                            originalRequest.headers.Authorization = 'Bearer ' + access_token;
                            resolve(axios(originalRequest));
                        });
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @description fn to get the updated access token
 * @param store redux store to execute logout
 * @returns promise
 */
var updateAuthToken = function (store) {
    var user = localStorage.getItem('userInfo');
    var userObject = JSON.parse(user);
    var refreshToken = userObject.refreshToken;
    return axios
        .post("".concat(CARE_PORTAL_URL, "cognito/authentication/access-token"), {
        email: userObject.email,
        refresh_token: refreshToken,
    })
        .then(function (res) {
        var resp = res.data;
        if (resp.application_code === 200) {
            var careAgentDetails = getCareAgentInfo();
            var loginUserDetails = __assign(__assign({}, careAgentDetails), { email: userObject.email, accessToken: resp.data[0].jwt, refreshToken: resp.data[0].refresh_token });
            isAlreadyFetchingAccessToken = false;
            saveUserInfo(loginUserDetails);
            onAccessTokenFetched(loginUserDetails.accessToken);
            return true;
        }
    })
        .catch(function (err) {
        isAlreadyFetchingAccessToken = false;
        injectStorageEvent(CACHE_EVENTS_AUTO_LOGOUT.SESSION_FLUSH, 401);
        store.dispatch(logoutCache(SESSION_EXPIRED_MESSAGE));
        if (err.response.status !== 403) {
            return Promise.reject(err);
        }
    });
};
// Interceptors let's you handle the request before it is handles by then and catch.
// Adding an interceptor for request.
/**
 * @description Axios interceptor to modify the API call.
 * @param store redux store
 */
var interceptor = function (store) {
    axios.interceptors.request.use(function (config) {
        var user = localStorage.getItem('userInfo');
        var userObject = JSON.parse(user);
        config.signal = controller.signal;
        // Checking if userObject is not empty.
        if (userObject) {
            config.headers.Authorization = "Bearer ".concat(userObject.accessToken);
        }
        if (!userObject) {
            var isPublicApi = Object.values(PUBLIC_APIS).some(function (endPoint) { var _a; return config.url === "".concat((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin, "/api/").concat(endPoint); });
            if (!isPublicApi) {
                controller.abort();
                controller = new AbortController();
            }
        }
        return config;
    });
    //response interceptor to refresh token on receiving token expired error
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        return __awaiter(this, void 0, void 0, function () {
            var user, userObject, originalRequest, refreshToken, retryOriginalRequest, authTokenError_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        user = localStorage.getItem('userInfo');
                        userObject = JSON.parse(user);
                        originalRequest = error.config;
                        refreshToken = userObject === null || userObject === void 0 ? void 0 : userObject.refreshToken;
                        //user will logout if response 403
                        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 403) {
                            store.dispatch(logoutCache('Forbidden', 'error'));
                        }
                        if (!(refreshToken &&
                            ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 401 &&
                            !originalRequest._retry)) return [3 /*break*/, 7];
                        originalRequest._retry = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        if (!!isAlreadyFetchingAccessToken) return [3 /*break*/, 3];
                        isAlreadyFetchingAccessToken = true;
                        retryOriginalRequest = retryRequest(originalRequest);
                        return [4 /*yield*/, updateAuthToken(store)];
                    case 2:
                        _c.sent(); // fetch authtoken
                        return [3 /*break*/, 4];
                    case 3:
                        retryOriginalRequest = retryRequest(originalRequest);
                        _c.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        authTokenError_1 = _c.sent();
                        return [2 /*return*/, Promise.reject(authTokenError_1)];
                    case 6: return [2 /*return*/, retryOriginalRequest];
                    case 7: return [2 /*return*/, Promise.reject(error)];
                }
            });
        });
    });
};
export default interceptor;
