var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, Tooltip } from '@mui/material';
import SeniorDetail from 'pages/WCPages/SeniorDashboard/components/SeniorDetails/SeniorDetail.component';
import ResidentDetailSummary from '../AllResidents/component/ResidentDetailSummary/ResidentDetailSummary';
import { useQuery } from 'utilities/react-query';
import { getActivityGraphService } from 'services/facilityGraphServices/activityGraphService/activityGraph.service';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { showError } from 'store/commonReducer/common.action';
import React from 'react';
import { getActivityChartTickValues, getScoreAndTarget, } from '../FacilityDashboard/component/ZoneChart/ZoneChart.types';
import { MONTHS } from 'globals/global.constants';
import ZoneChart from '../FacilityDashboard/component/ZoneChart/ZoneChart.component';
import GoalPercentage from './components/GoalPercentage/GoalPercentage';
import HolisticAssessment from './components/HolisticAssessment/HolisticAssessment';
import FacilitySummaryTable from './components/FacilitySummaryTable/FacilitySummaryTable.component';
import { residentDashboardStyle } from './ResidentDashboard.style';
import EmotionalSurveyCard from './components/EmotionalSurveyCard/EmotionalSurveyCard.component';
import InfoIcon from '@mui/icons-material/Info';
// import HomeSafetyAssessment from './components/HomeSafetyAssessment/HomeSafetyAssessment';
var IndependentLivingResidentDashboard = function () {
    var classes = residentDashboardStyle().classes;
    var seniorDetail = useAppSelector(function (state) { return state.common; }).seniorDetail;
    var minimalInfo = seniorDetail.minimalInfo;
    var careInsightHistory = useAppSelector(function (state) { return state.seniorDashboard.careInsightHistory.data; });
    var careInsightHistoryLoading = useAppSelector(function (state) { return state.seniorDashboard.careInsightHistory.loading; });
    var zone = React.useMemo(function () {
        var _a;
        return ((_a = minimalInfo === null || minimalInfo === void 0 ? void 0 : minimalInfo.zone) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
    }, [minimalInfo === null || minimalInfo === void 0 ? void 0 : minimalInfo.zone]);
    var zones = React.useMemo(function () { return ({
        overall: true,
        blue: false,
        green: false,
        gold: false,
    }); }, []);
    var dispatch = useAppDispatch();
    var facilityType = React.useMemo(function () {
        return minimalInfo.facility_type
            .split(' ')
            .map(function (x) {
            if (x === void 0) { x = ''; }
            return x.toLowerCase();
        })
            .join('_');
    }, [minimalInfo.facility_type]);
    if (facilityType === 'home_care') {
        facilityType = 'memory_care';
    }
    //activity graph query
    var _a = useQuery({
        queryKey: ['facilityActivityGraph'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var apiParams, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apiParams = {
                            duration: 3,
                            facility_type: facilityType,
                            senior_id: minimalInfo.user_id,
                            zone: zone,
                        };
                        return [4 /*yield*/, getActivityGraphService(apiParams)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
        enabled: !!facilityType,
    }), activityGraphData = _a.data, isActivityGraphLoading = _a.isLoading;
    /**
     * @description function to convert activityData to Graph Data
     * @function getGraphDataFromActivityGraphData
     * @param {IActivityGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromActivityGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, score = _a.score;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: score || 0,
            };
        });
        return graphData || [];
    }, []);
    var activityData = React.useMemo(function () {
        var _a;
        return {
            overall: getGraphDataFromActivityGraphData((_a = activityGraphData === null || activityGraphData === void 0 ? void 0 : activityGraphData[zone]) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromActivityGraphData([]),
            green: getGraphDataFromActivityGraphData([]),
            gold: getGraphDataFromActivityGraphData([]),
        };
    }, [activityGraphData, zone]);
    var _b = React.useMemo(function () {
        return getScoreAndTarget(zones, activityGraphData);
    }, [activityGraphData, zones]), activityScore = _b.score, activityTarget = _b.target;
    var activityTickValues = React.useMemo(function () {
        return getActivityChartTickValues(activityData, activityTarget, 10000);
    }, [activityData, activityTarget]);
    var summaryData = React.useMemo(function () {
        return careInsightHistory ? careInsightHistory.slice(0, 20) : [];
    }, [careInsightHistory]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Understanding Average Activity Trends" }), _jsx(Typography, { component: 'div', children: "Activity data, sourced from ClearWellness smartwatches allocated to residents at program commencement, is calculated using a proprietary point system. This system encourages older adults to strive for a daily minimum of 40,000 points. Personalized goals are then collaboratively established by the ClearWellness care team to facilitate residents in achieving their desired scores effectively." })] }));
    var tooltipText2 = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Weekly Wellness Updates" }), _jsx(Typography, { component: 'div', children: "Wellness coaches send weekly messages to provide progress updates on residents' well-being, assistance requirements, and caregiver concerns. This regular communication ensures all parties stay informed and enables tailored support and care for residents throughout their journey." })] }));
    return (_jsxs(Box, { display: 'flex', margin: '24px', gap: '24px', children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', width: '397px', minWidth: '397px', maxWidth: '397px', gap: '24px', children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', gap: '16px', children: [_jsx(SeniorDetail, { isResident: true }), _jsx(ResidentDetailSummary, {})] }), _jsx(Box, { width: 'inherit', flexGrow: 1, children: _jsx(ZoneChart, { state: {
                                zones: zones,
                                months: 3,
                                facilityType: minimalInfo.facility_type === 'home_care' ? 'memory_care' : facilityType,
                            }, data: activityData, title: _jsx(Tooltip, { title: tooltipText, placement: 'right', children: _jsxs(Typography, { variant: 'h6', children: ["Average Activity Trends:", _jsx(InfoIcon, { fontSize: 'medium', style: { display: 'inline-flex', marginLeft: '98px', position: "relative", top: "5px" } })] }) }), current: true, currentLabel: 'Current Month Average:', currentValue: activityScore === null || activityScore === void 0 ? void 0 : activityScore.toLocaleString(), target: activityTarget, isLoading: isActivityGraphLoading, yAxisProps: {
                                tickValues: activityTickValues,
                                tickFormat: function (t) { return (t ? "".concat(Math.ceil(t / 1000), "k") : t); },
                            }, showLegend: false, showTarget: true, isSmall: true }) })] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', width: '397px', minWidth: '397px', maxWidth: '397px', gap: '24px', children: [_jsx(GoalPercentage, {}), _jsx(HolisticAssessment, {})] }), _jsxs(Box, { display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: '480px', gap: '24px', children: [_jsx(Box, { children: _jsxs(Box, { className: classes.card, children: [_jsxs(Box, { padding: '12px 20px', style: { display: 'flex' }, children: [_jsx(Typography, { variant: 'h2', color: 'WindowText', children: "Messages" }), _jsx(Tooltip, { title: tooltipText2, children: _jsx(Box, { children: _jsx(InfoIcon, { style: { marginLeft: '318px', position: "relative", top: "5px" }, fontSize: 'medium' }) }) })] }), _jsx(FacilitySummaryTable, { data: summaryData || [], loading: careInsightHistoryLoading, height: '360px' })] }) }), _jsx(Box, { children: _jsx(EmotionalSurveyCard, {}) })] })] }));
};
export default IndependentLivingResidentDashboard;
