var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { disableFacilityAgentService, getCorpFacilityUserService, } from 'services/coporateAndFacilitiesService/FacilityManagementService/facilitymanagement.service';
import { getPaginationOffsetData, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { DIALOG_TYPES, FETCH_LIMIT, PAGINATION_LIMIT, } from 'globals/global.constants';
export var GET_CORP_FACILITY_USERS = 'GET_CORP_FACILITY_USERS';
export var SET_CORP_FACILITY_USER_SEARCH = 'SET_CORP_FACILITY_USER_SEARCH';
export var GET_CORP_FACILITY_USERS_SUCCESS = 'GET_CORP_FACILITY_USERS_SUCCESS';
export var GET_CORP_FACILITY_USERS_FAIL = 'GET_CORP_FACILITY_USERS_FAIL';
export var GET_CORP_FACILITY_USERS_SEARCH_SUCCESS = 'GET_CORP_FACILITY_USERS_SEARCH_SUCCESS';
export var UPDATE_CORP_FACILITY_USERS_PAGE_NUMBER = 'UPDATE_CORP_FACILITY_USERS_PAGE_NUMBER';
export var RESET_CORP_FACILITY_USERS_TABLE = 'RESET_CORP_FACILITY_USERS_TABLE';
export var SET_ACCOUNT_FILTER_CORPORATE_NAME = 'SET_ACCOUNT_FILTER_CORPORATE_NAME';
/**
 * @description this action creator will fetch the care agent of corporate and facility
 * @function getCorpFacilityUser
 */
export var getCorpFacilityUser = function (offset, limit, searchQuery) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var corporateId, params, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(showApplicationLoader());
                    corporateId = getState().corporateAndFacilities.careAgents.corporateIdFilter;
                    dispatch({ type: GET_CORP_FACILITY_USERS });
                    params = {
                        limit: limit,
                        offset: offset,
                        agent: 'FACA',
                    };
                    if (corporateId) {
                        params.corporate_id = corporateId;
                    }
                    if (searchQuery) {
                        params.agent_name = searchQuery;
                    }
                    return [4 /*yield*/, getCorpFacilityUserService(params)];
                case 1:
                    response = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, {
                            data: (response === null || response === void 0 ? void 0 : response.data) || [],
                        }];
                case 2:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_1));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function getCorpFacilityUsersSuccess
 * @description  action creator to set corporate & facility users on success
 */
export var getCorpFacilityUsersSuccess = function (data, offset) { return function (dispatch) {
    dispatch({
        type: GET_CORP_FACILITY_USERS_SUCCESS,
        payload: {
            data: data,
            offset: offset,
        },
    });
}; };
/**
 * @function getCorpFacilityUsersFail
 * @description action creator to fetch error on getCorpFacilityUser fail
 * @param error
 */
export var getCorpFacilityUsersFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_CORP_FACILITY_USERS_FAIL,
    });
}; };
/**
 * @function getCorpFacilityUserSearchSuccess
 * @description action creator to search user
 * @param tableData
 */
export var getCorpFacilityUserSearchSuccess = function (data, offset) { return function (dispatch) {
    dispatch({
        type: GET_CORP_FACILITY_USERS_SEARCH_SUCCESS,
        payload: {
            data: data,
            offset: offset,
        },
    });
}; };
/**
 * @function updateCorporatePageNumber
 * @description action creator to update the page number of corporate management table
 * @param {string | number} value
 */
export var updateCorpFacilityUserPageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_CORP_FACILITY_USERS_PAGE_NUMBER,
        payload: value,
    });
}; };
/**
 * @function resetCorpFacilityUsers
 * @description action creator to reset resetCorpFacilityUsers table
 */
export var resetCorpFacilityUsers = function () { return function (dispatch) {
    dispatch({
        type: RESET_CORP_FACILITY_USERS_TABLE,
    });
}; };
/**
 * @description method to flter data on FE, search by name
 * @function searchByName
 * @param {any[]} data
 * @param {string} searchQuery
 * @returns {any[]}
 */
export var searchCorpUserByName = function (data, searchQuery) {
    if (data === void 0) { data = []; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return data.filter(function (corporate) {
        var query = searchQuery.toLowerCase().split(' ');
        var corpName = corporate.name.toLowerCase() || '';
        return corpName.includes(query);
    });
};
/**
 * @functions disableFacilityAgent
 * @description action creator to disable care agent account.
 * @param {*} data
 */
export var disableFacilityAgent = function (data) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var params, userSearchValue_1, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    email: data.email,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                userSearchValue_1 = getState().corporateAndFacilities.careAgents.userSearchValue;
                dispatch(showApplicationLoader());
                return [4 /*yield*/, disableFacilityAgentService(params)];
            case 2:
                _a.sent();
                // dispatch(resetCorpFacilityUsers()); //on reset filter values also get reset
                dispatch(getPaginationOffsetData(function () { return getCorpFacilityUser(0, 100, userSearchValue_1); }, PAGINATION_LIMIT.corpFacilityUsers, 1, 0, FETCH_LIMIT.corpFacilityUsers, getCorpFacilityUsersSuccess, getCorpFacilityUsersFail, [], ''));
                dispatch(openOverlayDialog({
                    type: DIALOG_TYPES.SUCCESS,
                    firstMessage: "User ".concat(data.name.firstName, " ").concat(data.name.lastName, " disabled successfully"),
                }));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 3:
                error_2 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_2));
                return [2 /*return*/, error_2];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
