var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { FACILITY_MANAGEMENT_END_POINTS } from 'globals/apiEndPoints';
import { APIMethod } from 'globals/enums';
import CorpFacilityUsersParser from './parser/corpFacilityUsersParser';
/**
 * @function getFacilityManagementService
 * @description method to get facility data via api response.
 * @param {*} params
 * @returns facility accounts.
 */
export function getFacilityManagementService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, results, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: FACILITY_MANAGEMENT_END_POINTS.GET_FACILITY_LIST,
                            method: APIMethod.Get,
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    results = response.data.list;
                    return [2 /*return*/, {
                            data: createFacilityListData(results) || [],
                            lastEvaluatedKey: response.data.last_evaluated_key,
                            totalResidents: response.data.total_residents,
                        }];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @description disabling the facility
 * @param param
 * @returns
 */
export function disableFacilityService(facilityData) {
    return __awaiter(this, void 0, Promise, function () {
        var param, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    param = {
                        facility_id: facilityData.id,
                    };
                    return [4 /*yield*/, API({
                            url: FACILITY_MANAGEMENT_END_POINTS.DISABLE_FACILITY,
                            method: APIMethod.Delete,
                            data: param,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, { facilityName: facilityData.name }];
                case 2:
                    error_2 = _a.sent();
                    throw new Error(error_2.response.data.message);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function postFacilityService
 * @description to post updated facility list
 * @param {IFacilityManagementAPIData} param
 */
export function postFacilityService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: FACILITY_MANAGEMENT_END_POINTS.POST_FACILITY,
                            method: APIMethod.Post,
                            data: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    console.error(error_3);
                    throw new Error(error_3);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function searchFacilityService
 * @description to get list of auto suggetion on search for any facility
 */
export function searchFacilityService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: FACILITY_MANAGEMENT_END_POINTS.SEARCH_FACILITY,
                            method: APIMethod.Get,
                            params: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data || []]; //parse is not required as we get single array in result
                case 2:
                    error_4 = _a.sent();
                    console.error(error_4);
                    throw new Error(error_4);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @description to get list of parsed facilty
 * @param {IFacilityListAPIData}
 * @returns {IFacilityData[]}
 */
var createFacilityListData = function (response) {
    var mappedData = response.map(function (facility) {
        return {
            facilityName: facility.name,
            cityAndState: {
                city: (facility === null || facility === void 0 ? void 0 : facility.address_city) || '',
                state: (facility === null || facility === void 0 ? void 0 : facility.address_state) || '',
            },
            phone: facility.phone_office,
            residents: facility.resident_count || 0,
            id: facility.id,
        };
    });
    return mappedData;
};
export function validateDuplicateValuesService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: FACILITY_MANAGEMENT_END_POINTS.VALIDATE_DUPLICATE,
                            method: APIMethod.Post,
                            data: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data || []]; //parse is not required as we get single array in result
                case 2:
                    error_5 = _a.sent();
                    console.error(error_5);
                    throw new Error(error_5);
                case 3: return [2 /*return*/];
            }
        });
    });
}
export var getCorpFacilityUserService = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, dataParser, corpAgentList, error_6;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, API({
                        url: FACILITY_MANAGEMENT_END_POINTS.CORP_FACILITY_USERS,
                        method: APIMethod.Get,
                        params: params,
                    })];
            case 1:
                response = _b.sent();
                dataParser = new CorpFacilityUsersParser();
                corpAgentList = dataParser.parse(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.agents) || []);
                return [2 /*return*/, {
                        data: corpAgentList || [],
                    }];
            case 2:
                error_6 = _b.sent();
                throw new Error(error_6);
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * @description service to disable account
 * @function disableFacilityAgentService
 * @param param
 * @returns
 */
export function disableFacilityAgentService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, API({
                    url: FACILITY_MANAGEMENT_END_POINTS.DISABLE_FACILITY_AGENT_ACCOUNT,
                    method: APIMethod.Delete,
                    params: params,
                })];
        });
    });
}
