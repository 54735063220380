var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, TextField, InputAdornment, IconButton, } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Label } from 'common/Input';
import Info from 'assets/icons/Info.svg';
import Correct from 'assets/icons/Correct.svg';
import Wrong from 'assets/icons/Wrong.svg';
import { resetPassword, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { DIALOG_TYPES } from 'globals/global.constants';
import { updatePasswordStyle } from './UpdatePassword.style';
import { postValidatePasswordService, postChangePasswordService, } from 'services/userService/userService';
import { useMutation } from '@tanstack/react-query';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { removeLocalStorage } from 'globals/global.functions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { theme } from 'config/theme.config';
import ErrorIcon from 'common/Icons/ErrorIcon';
var UpdatePassword = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = _a.showCancelBtn, showCancelBtn = _o === void 0 ? false : _o, _p = _a.heading, heading = _p === void 0 ? '' : _p, _q = _a.showOldPasswordField, showOldPasswordField = _q === void 0 ? false : _q, _r = _a.alwaysShowPassCriteria, alwaysShowPassCriteria = _r === void 0 ? false : _r, _s = _a.placeholderText, placeholderText = _s === void 0 ? 'Enter Password' : _s;
    var classes = updatePasswordStyle().classes;
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _t = useAppSelector(function (state) { return state.auth.passwordCreatedData; }), isPasswordResetWarning = _t.isPasswordResetWarning, passwordExpiryDate = _t.passwordExpiryDate;
    var defaultPage = useAppSelector(function (state) { var _a; return (_a = state.auth.roleConfig) === null || _a === void 0 ? void 0 : _a.defaultPage; }) || '/';
    var _u = React.useState({
        new: false,
        reEnter: false,
        old: false,
    }), showPassword = _u[0], setShowPassword = _u[1];
    var _v = React.useState({
        charLimit: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        noSeq: false,
    }), validator = _v[0], setValidator = _v[1];
    var _w = React.useState({
        reEnterErrorMessage: '',
        oldPasswordErrorMsg: '',
        newPasswordError: false,
    }), error = _w[0], setError = _w[1];
    var _x = React.useState(false), showPasswordCriteria = _x[0], setShowPasswordCriteria = _x[1];
    // Mutation Object for ValidatePassword Service
    var validatePasswordMutation = useMutation({
        mutationFn: function (data) {
            dispatch(showApplicationLoader());
            return postValidatePasswordService(data);
        },
        onSuccess: function (isValid) {
            dispatch(hideApplicationLoader());
            setError(function (error) { return (__assign(__assign({}, error), { oldPasswordErrorMsg: isValid ? '' : 'Incorrect Old Password' })); });
        },
        onError: function () {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    // Mutation Object for ChangePassword Service
    var changePasswordMutation = useMutation({
        mutationFn: function (data) {
            dispatch(showApplicationLoader());
            return postChangePasswordService(data);
        },
        onSuccess: function () {
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: 'You have successfully changed your password. Please re-login using the new password',
                isLogout: true,
            }));
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    /**
     * @function matchOldPassword
     * @description validator that runs onBlur event for lod password field
     */
    var matchOldPassword = React.useCallback(function (e) {
        if (e.target.value) {
            validatePasswordMutation.mutate({
                password: e.target.value,
            });
        }
    }, [validatePasswordMutation]);
    var handleSubmit = function (e) {
        var _a;
        e.preventDefault();
        var data = new FormData(e.target);
        var newPassword = data.get('new-password');
        var reEnterPassword = data.get('reenter-password');
        var oldPassword = data.get('old-password');
        if (newPassword && !error.newPasswordError) {
            if (newPassword === reEnterPassword) {
                if (alwaysShowPassCriteria) {
                    var query = new URLSearchParams(location.search);
                    var otp = query.get('otp');
                    var email = (_a = query.get('email')) === null || _a === void 0 ? void 0 : _a.replace(/ /g, '+');
                    dispatch(resetPassword(otp, email, newPassword));
                }
                else if (showOldPasswordField &&
                    oldPassword &&
                    !error.oldPasswordErrorMsg) {
                    if (oldPassword === newPassword) {
                        dispatch(showToast('New Password should not be similar to Old Password', 'error'));
                    }
                    else {
                        changePasswordMutation.mutate({
                            old_password: oldPassword.toString(),
                            new_password: newPassword.toString(),
                        });
                    }
                }
            }
            else {
                setError(__assign(__assign({}, error), { reEnterErrorMessage: 'Passwords do not match' }));
            }
        }
    };
    var passwordCriteriaValidator = function (value) {
        var validatorObject = {
            charLimit: false,
            uppercase: false,
            lowercase: false,
            specialChar: false,
            noSeq: false,
        };
        if (/(?=.*?[A-Z])/.test(value)) {
            validatorObject.uppercase = true;
        }
        if (/.{14,}/.test(value)) {
            validatorObject.charLimit = true;
        }
        if (/(?=.*?[a-z])/.test(value)) {
            validatorObject.lowercase = true;
        }
        if (/(?=.*?[#?!@$%^&*-])/.test(value)) {
            validatorObject.specialChar = true;
        }
        if (/(?=.*?\d)/.test(value) && checkSeq(value)) {
            validatorObject.noSeq = true;
        }
        return validatorObject;
    };
    var validatePassword = function (e) {
        setError(function (error) { return (__assign(__assign({}, error), { reEnterErrorMessage: '' })); });
        var value = e.target.value;
        var passwordCriteria = alwaysShowPassCriteria
            ? alwaysShowPassCriteria
            : value;
        if (value) {
            setShowPasswordCriteria(true);
        }
        else {
            setShowPasswordCriteria(false);
        }
        if (passwordCriteria) {
            var validatorObject_1 = passwordCriteriaValidator(value);
            setValidator(validatorObject_1);
            setError(function (error) { return (__assign(__assign({}, error), { newPasswordError: Object.values(validatorObject_1).some(function (v) { return v !== true; }) })); });
        }
    };
    var checkSeq = function (num) {
        var arrNum = ('' + num).split('');
        return arrNum.every(function (data, index) {
            var intitalNum = parseInt(arrNum[index]);
            var secondNum = parseInt(arrNum[index + 1]);
            var thirdNum = parseInt(arrNum[index + 2]);
            var diff1 = secondNum - intitalNum;
            var diff2 = thirdNum - secondNum;
            if (!isNaN(diff1) && !isNaN(diff2)) {
                if (diff1 === 1 && diff2 === 1)
                    return false;
            }
            return true;
        });
    };
    var onCancelHandler = function () {
        navigate(defaultPage);
    };
    React.useEffect(function () {
        return function () {
            if (changePasswordMutation.isSuccess) {
                removeLocalStorage('userInfo');
            }
        };
    }, [changePasswordMutation.isSuccess]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: 'h1', className: classes.headingText, children: heading }), isPasswordResetWarning && (_jsxs(Box, { display: 'flex', children: [_jsx(Box, { display: 'flex', alignItems: 'flex-end', children: _jsx(ErrorIcon, { className: classes.errorIcon }) }), _jsxs(Typography, { variant: 'body1', style: { color: theme.palette.error.light, marginTop: '10px' }, children: ["Your password will expire on ", passwordExpiryDate, ". Please change your password now to avoid inconvenience."] })] })), _jsx(Box, { width: 1, py: 3, px: 8, "data-testid": 'choose-password', children: _jsxs("form", { onSubmit: handleSubmit, "data-testid": 'choosePasswordForm', noValidate: true, children: [showOldPasswordField && (_jsxs(Box, { pb: 3, children: [_jsx(Label, { htmlFor: 'password', className: clsx((_b = {},
                                        _b[classes.isError] = error.oldPasswordErrorMsg,
                                        _b)), children: "Enter Old Password" }), _jsx(TextField, { id: 'old-password', name: 'old-password', fullWidth: true, variant: 'outlined', type: showPassword.old ? 'text' : 'password', placeholder: 'Enter Password', className: clsx((_c = {},
                                        _c[classes.inputField] = true,
                                        _c[classes.errorField] = error.oldPasswordErrorMsg,
                                        _c)), InputProps: {
                                        endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(IconButton, { "aria-label": 'toggle password visibility', onClick: function () {
                                                    return setShowPassword(function (showPassword) { return (__assign(__assign({}, showPassword), { old: !showPassword.old })); });
                                                }, "data-testid": 'oldPasswordIconBtn', size: 'large', children: showPassword.old ? (_jsx(Visibility, { className: classes.visibility })) : (_jsx(VisibilityOff, { className: classes.visibility })) }) })),
                                    }, onChange: function () {
                                        setError(function (error) { return (__assign(__assign({}, error), { oldPasswordErrorMsg: '' })); });
                                    }, onMouseLeave: matchOldPassword }), _jsx(Typography, { variant: 'subtitle1', className: classes.errorText, children: error.oldPasswordErrorMsg })] })), _jsxs(Box, { pb: 2, children: [_jsx(Label, { htmlFor: 'email', className: clsx((_d = {},
                                        _d[classes.isError] = error.newPasswordError,
                                        _d)), children: "Enter New Password" }), _jsx(TextField, { id: 'new-password', name: 'new-password', fullWidth: true, variant: 'outlined', type: showPassword.new ? 'text' : 'password', placeholder: placeholderText, InputProps: {
                                        endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(IconButton, { "aria-label": 'toggle password visibility', onClick: function () {
                                                    return setShowPassword(function (showPassword) { return (__assign(__assign({}, showPassword), { new: !showPassword.new })); });
                                                }, "data-testid": 'newPasswordIconBtn', size: 'large', children: showPassword.new ? (_jsx(Visibility, { className: classes.visibility })) : (_jsx(VisibilityOff, { className: classes.visibility })) }) })),
                                    }, onChange: validatePassword, className: clsx((_e = {},
                                        _e[classes.inputField] = true,
                                        _e[classes.errorField] = error.newPasswordError,
                                        _e)) }), (alwaysShowPassCriteria
                                    ? alwaysShowPassCriteria
                                    : showPasswordCriteria) && (_jsxs(Box, { mt: 1, children: [_jsxs(Box, { className: classes.errorTextWrapper, display: 'flex', alignItems: 'center', children: [_jsx("img", { src: Info }), _jsx(Typography, { variant: 'body1', className: classes.infoText, children: "No reusing old password" })] }), _jsxs(Box, { className: classes.errorTextWrapper, children: [_jsx("img", { src: validator.charLimit ? Correct : Wrong }), _jsx(Typography, { variant: 'body1', className: clsx(classes.errorText, (_f = {},
                                                        _f[classes.successText] = validator.charLimit,
                                                        _f)), children: "At least 14 character long" })] }), _jsxs(Box, { className: classes.errorTextWrapper, children: [_jsx("img", { src: validator.uppercase ? Correct : Wrong }), _jsx(Typography, { variant: 'body1', className: clsx(classes.errorText, (_g = {},
                                                        _g[classes.successText] = validator.uppercase,
                                                        _g)), children: "At least one uppercase letter" })] }), _jsxs(Box, { className: classes.errorTextWrapper, children: [_jsx("img", { src: validator.lowercase ? Correct : Wrong }), _jsx(Typography, { variant: 'body1', className: clsx(classes.errorText, (_h = {},
                                                        _h[classes.successText] = validator.lowercase,
                                                        _h)), children: "At least one lowercase letter" })] }), _jsxs(Box, { className: classes.errorTextWrapper, children: [_jsx("img", { src: validator.specialChar ? Correct : Wrong }), _jsx(Typography, { variant: 'body1', className: clsx(classes.errorText, (_j = {},
                                                        _j[classes.successText] = validator.specialChar,
                                                        _j)), children: "At least one special character" })] }), _jsxs(Box, { className: classes.errorTextWrapper, children: [_jsx("img", { src: validator.noSeq ? Correct : Wrong }), _jsx(Typography, { variant: 'body1', className: clsx(classes.errorText, (_k = {},
                                                        _k[classes.successText] = validator.noSeq,
                                                        _k)), children: "At least one number with no sequence of numbers" })] })] }))] }), _jsxs(Box, { pb: 3, children: [_jsx(Label, { htmlFor: 'password', className: clsx((_l = {},
                                        _l[classes.isError] = error.reEnterErrorMessage,
                                        _l)), children: "Re-enter New Password" }), _jsx(TextField, { id: 'reenter-password', name: 'reenter-password', fullWidth: true, variant: 'outlined', type: showPassword.reEnter ? 'text' : 'password', placeholder: placeholderText, className: clsx((_m = {},
                                        _m[classes.inputField] = true,
                                        _m[classes.errorField] = error.reEnterErrorMessage,
                                        _m)), onChange: function () {
                                        setError(function (error) { return (__assign(__assign({}, error), { reEnterErrorMessage: '' })); });
                                    }, InputProps: {
                                        endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(IconButton, { "aria-label": 'toggle password visibility', onClick: function () {
                                                    return setShowPassword(function (showPassword) { return (__assign(__assign({}, showPassword), { reEnter: !showPassword.reEnter })); });
                                                }, "data-testid": 'reEnterPasswordIconBtn', size: 'large', children: showPassword.reEnter ? (_jsx(Visibility, { className: classes.visibility })) : (_jsx(VisibilityOff, { className: classes.visibility })) }) })),
                                    } }), _jsx(Typography, { variant: 'subtitle1', className: classes.errorText, children: error.reEnterErrorMessage })] }), _jsxs(Box, { display: 'flex', justifyContent: showCancelBtn ? 'center' : '', pb: 5, children: [showCancelBtn && (_jsx(Button, { type: 'submit', size: 'large', variant: 'outlined', onClick: onCancelHandler, className: 'secondaryButton', style: {
                                        marginRight: 25,
                                        boxShadow: '0 10px 15px 0 rgba(0, 126, 154, 0.15)',
                                    }, children: "Cancel" })), _jsx(Button, { fullWidth: true, type: 'submit', color: 'primary', variant: 'contained', size: 'large', "data-testid": 'submitButton', style: {
                                        boxShadow: '0 10px 15px 0 rgba(0, 126, 154, 0.15)',
                                    }, children: "Submit" })] })] }) })] }));
};
export default UpdatePassword;
