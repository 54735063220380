var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { DIALOG_TYPES, ERROR_MESSAGE, PAGINATION_LIMIT, } from 'globals/global.constants';
import { getMedicationListService, postMedicationListService, searchMedicationService, } from 'services/assessmentService/medicationService/medicationService';
import { closeDialog, openDialog, openOverlayDialog, showError, getPaginationDataIsolated, } from 'store/commonReducer/common.action';
import { AssessmentStatus } from 'globals/enums';
import { getCurrentSenior } from 'globals/global.functions';
import { getAssessmentStatus } from '../AssessmentStatus/AssessmentStatus.action';
export var GET_MEDICATION_LIST = 'GET_MEDICATION_LIST';
export var GET_MEDICATION_LIST_SUCCESS = 'GET_MEDICATION_LIST_SUCCESS';
export var GET_MEDICATION_LIST_FAIL = 'GET_MEDICATION_LIST_FAIL';
export var UPDATE_MEDICATION_LIST_PAGE_NUMBER = 'UPDATE_MEDICATION_LIST_PAGE_NUMBER';
export var GET_MEDICINE_SEARCH_RESULT = 'GET_MEDICINE_SEARCH_RESULT';
export var SEARCH_RESULT_SUCCESS = 'SEARCH_RESULT_SUCCESS';
export var SEARCH_RESULT_FAIL = 'SEARCH_RESULT_FAIL';
export var RESET_SEARCH = 'RESET_SEARCH';
export var MEDICATION_TOGGLE_IS_COMPLETED = 'MEDICATION_TOGGLE_IS_COMPLETED';
export var SET_MEDICATION_DIALOG_DATA = 'SET_MEDICATION_DIALOG_DATA';
export var EMPTY_MEDICATION_DIALOG_DATA = 'EMPTY_MEDICATION_DIALOG_DATA';
export var RESET_MEDICATION_DATA = 'RESET_MEDICATION_DATA';
/**
 * @description action creator to open Medication dialog
 * @function openMedicationDialog
 * @returns void
 */
export var openMedicationDialog = function (_a) {
    var medicationData = _a.medicationData, dialogTitle = _a.dialogTitle;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(openDialog({
                type: DIALOG_TYPES.MEDICATION,
                dialogTitle: dialogTitle,
                data: medicationData,
            }));
            return [2 /*return*/];
        });
    }); };
};
/**
 * @description action creator to fetch Medication Data
 * @function getMedicationData
 * @returns pagination data
 */
export var getMedicationData = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorID, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader('', false));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                seniorID = getCurrentSenior().seniorID;
                return [4 /*yield*/, getMedicationListService({ senior_id: seniorID })];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, {
                        data: response.data,
                    }];
            case 3:
                error_1 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_1));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getMedicationDataSuccess
 * @description action creator to store assessment history table data
 * @param tableData
 */
export var getMedicationDataSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data;
    dispatch({
        type: GET_MEDICATION_LIST_SUCCESS,
        payload: {
            data: data,
        },
    });
}; };
/**
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getMedicationFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_MEDICATION_LIST_FAIL,
    });
}; };
/**
 * @function updateMedicationPageNumber
 * @description action creator to update the page number medication list
 * @param {string | number} value
 */
export var updateMedicationPageNumber = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_MEDICATION_LIST_PAGE_NUMBER, payload: value });
}; };
/**
 * @function postMedicationData
 * @description action creator to update medication data
 */
export var postMedicationData = function (_a) {
    var data = _a.data, status = _a.status, seniorID = _a.seniorID, _b = _a.medicationID, medicationID = _b === void 0 ? '' : _b;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var medicationInfo, medicationInfoLength, params, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    medicationInfo = getState().medicationList.data.length + 1;
                    medicationInfoLength = medicationInfo.toString();
                    dispatch(showApplicationLoader('', false));
                    params = {
                        senior_id: seniorID,
                        status: status,
                        medicine: data === null || data === void 0 ? void 0 : data.medicationName,
                        dose_form: data === null || data === void 0 ? void 0 : data.doseForm,
                        dose_frequency: {
                            every: data === null || data === void 0 ? void 0 : data.doseFrequencyTime,
                            period: data === null || data === void 0 ? void 0 : data.doseFrequencyUnit,
                        },
                        when: data === null || data === void 0 ? void 0 : data.whenDoTheyTakeIt,
                        date_prescribed: data === null || data === void 0 ? void 0 : data.datePrescribed,
                        date_discontinued: data === null || data === void 0 ? void 0 : data.dateDiscontinued,
                        pharmacy_name: data === null || data === void 0 ? void 0 : data.pharmacyName,
                        pharmacy_phone_number: data === null || data === void 0 ? void 0 : data.pharmacyPhone,
                        notes: data === null || data === void 0 ? void 0 : data.notes,
                        total_meds: medicationInfoLength,
                    };
                    if (medicationID) {
                        params.medication_id = medicationID;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postMedicationListService(params)];
                case 2:
                    _a.sent();
                    //set form as complete
                    dispatch({
                        type: MEDICATION_TOGGLE_IS_COMPLETED,
                        payload: { isCompleted: true },
                    });
                    // Updating global AssessmentStatus
                    dispatch(getAssessmentStatus());
                    dispatch(getPaginationDataIsolated(function () { return getMedicationData(); }, PAGINATION_LIMIT.medication, '', 1, getMedicationDataSuccess, getMedicationFail, '', ''));
                    dispatch(hideApplicationLoader());
                    if (status === AssessmentStatus.Submit) {
                        dispatch(openOverlayDialog({
                            type: DIALOG_TYPES.SUCCESS,
                            firstMessage: 'Medication has been submitted successfully',
                        }));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_2));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function searchMedication
 * @discription to get list of auto suggetion on search for any medication
 * @param {string} value
 * @returns
 */
export var searchMedication = function (value) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var param, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                param = {
                    medicine: value,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch({ type: GET_MEDICINE_SEARCH_RESULT });
                return [4 /*yield*/, searchMedicationService(param)];
            case 2:
                response = _a.sent();
                dispatch({ type: SEARCH_RESULT_SUCCESS, payload: response });
                return [3 /*break*/, 4];
            case 3:
                error_3 = _a.sent();
                dispatch({ type: SEARCH_RESULT_FAIL, payload: ERROR_MESSAGE.SEARCH_ERROR });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function checkAndSaveMedicationDialog
 * @discription to save and close medication dialog
 * @returns
 */
export var checkAndSaveMedicationDialog = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorID, param;
    return __generator(this, function (_a) {
        seniorID = getCurrentSenior().seniorID;
        param = {
            data: data,
            status: AssessmentStatus.Save,
            seniorID: seniorID,
            medicationID: data.medicationID,
        };
        if (data === null || data === void 0 ? void 0 : data.medicationName) {
            dispatch(postMedicationData(param));
        }
        dispatch(closeDialog());
        dispatch(emptyMedicationDialogData());
        return [2 /*return*/];
    });
}); }; };
/**
 * @function setMedicationDialogData
 * @discription to set medication dialog data
 * @returns
 */
export var setMedicationDialogData = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({
            type: SET_MEDICATION_DIALOG_DATA,
            payload: data,
        });
        return [2 /*return*/];
    });
}); }; };
/**
 * @function emptyMedicationDialogData
 * @discription to empty medication dialog data
 * @returns void
 */
export var emptyMedicationDialogData = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({
            type: EMPTY_MEDICATION_DIALOG_DATA,
        });
        return [2 /*return*/];
    });
}); }; };
/**
 * @function resetAllMedicationData
 * @discription to reset all the medication data for a senior
 * @returns void
 */
export var resetAllMedicationData = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({
            type: RESET_MEDICATION_DATA,
        });
        return [2 /*return*/];
    });
}); }; };
