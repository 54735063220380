var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable max-len */
import { snakeCase } from 'lodash';
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { getFallsAndHospitalizationAssessmentAdminService, getFallsAndHospitalizationAssessmentHistoryAdminService, postFallsAndHospitalizationAssessmentAdminService, } from 'services/adminServices/fallsAndHospitalizationAssessmentAdmin.service';
import { getPaginationDataIsolated, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { DIALOG_TYPES, PAGINATION_LIMIT } from 'globals/global.constants';
import { FallsAndHospitalizationAssessmentAdminStatus } from 'globals/enums';
export var GET_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN = 'GET_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN';
export var GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN = 'GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN';
export var GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_SUCCESS = 'GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_SUCCESS';
export var GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_FAIL = 'GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_FAIL';
export var UPDATE_ASSESSMENT_HISTORY_ADMIN_PAGE_NUMBER = 'UPDATE_ASSESSMENT_HISTORY_ADMIN_PAGE_NUMBER';
export var TOGGLE_FALLSANDHOSPITALIZATION_ADMIN_VIEW_STATE = 'TOGGLE_FALLSANDHOSPITALIZATION_ADMIN_VIEW_STATE';
/**
 * @description action creator to fetch fallsAndHospitalization assessment admin
 * @function getFallsAndHospitalizationAssessmentAdmin
 */
export var getFallsAndHospitalizationAssessmentAdmin = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getFallsAndHospitalizationAssessmentAdminService()];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch({
                    type: GET_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN,
                    payload: __assign({}, response),
                });
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_1));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to fetch assessment history admin
 * @function getFallsAndHospitalizationAssessmentHistoryAdmin
 * @returns pagination data
 */
export var getFallsAndHospitalizationAssessmentHistoryAdmin = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var lastEvaluatedKey, param, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch({ type: GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN });
                lastEvaluatedKey = getState().fallsAndHospitalizationAssessmentAdmin.history.lastEvaluatedKey;
                param = {};
                if (lastEvaluatedKey) {
                    param.last_evaluated_key = lastEvaluatedKey;
                }
                return [4 /*yield*/, getFallsAndHospitalizationAssessmentHistoryAdminService(param)];
            case 1:
                response = _a.sent();
                if (!response.data || response.data.length === 0) {
                    // Handle the case when data is empty but the response is 200
                    console.log('Data is empty, but response is 200');
                    return [2 /*return*/, {
                            data: [],
                            lastEvaluatedKey: null,
                        }];
                }
                return [2 /*return*/, {
                        data: response.data,
                        lastEvaluatedKey: response.lastEvaluatedKey,
                    }];
            case 2:
                error_2 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_2));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getAssessmentHistoryAdminSuccess
 * @description action creator to store assessment history table data
 * @param {IAssessmentHisotry} tableData
 */
export var getAssessmentHistoryAdminSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    dispatch({
        type: GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_SUCCESS,
        payload: {
            data: data,
            lastEvaluatedKey: lastEvaluatedKey,
        },
    });
}; };
/**
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getAssessmentHistoryAdminFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN_FAIL,
    });
}; };
/**
 * @function updateAssessmentHistoryAdminPageNumber
 * @description action creator to update the page number of history table
 * @param {string | number} value
 */
export var updateAssessmentHistoryAdminPageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_ASSESSMENT_HISTORY_ADMIN_PAGE_NUMBER,
        payload: value,
    });
}; };
/**
 * @function toggleFallsAndHospitalizationAssessmentAdminViewState
 * @description action creator to toggle view state
 * @param {boolean} isHistory
 */
export var toggleFallsAndHospitalizationAssessmentAdminViewState = function (isHistory) { return function (dispatch) {
    dispatch({
        type: TOGGLE_FALLSANDHOSPITALIZATION_ADMIN_VIEW_STATE,
        payload: { isHistory: isHistory },
    });
}; };
/**
 * @function postFallsAndHospitalizationAssessmentAdmin
 * @description action creator to save/submit/reset assessment
 * @param {any} param
 */
export var postFallsAndHospitalizationAssessmentAdmin = function (_a) {
    var param = __rest(_a, []);
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var careAgentId, careAgentName, exitingSurveyForm, dataParam, params, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(showApplicationLoader());
                    careAgentId = getState().auth.userId;
                    careAgentName = getState().auth.userName;
                    exitingSurveyForm = getState().fallsAndHospitalizationAssessmentAdmin.survey;
                    dataParam = param.surveyData.map(function (_a) {
                        var _b;
                        var data = _a.data, header = _a.header;
                        var surveyKey = snakeCase(header);
                        return _b = {}, _b[surveyKey] = __spreadArray([], data, true), _b;
                    });
                    params = {
                        created_by: careAgentId,
                        created_by_name: "".concat(careAgentName.first_name, " ").concat(careAgentName.last_name),
                        form_status: param.type,
                        version_number: param.versionNumber,
                        form_id: param.formId,
                        form: param.type == FallsAndHospitalizationAssessmentAdminStatus.Reset
                            ? exitingSurveyForm
                            : dataParam,
                        options: {
                            comment: 3,
                            yes: 1,
                            no: 2,
                        },
                    };
                    if (param.type == FallsAndHospitalizationAssessmentAdminStatus.SubmitLater) {
                        params.publish_date_time = param.publishDateTime;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, postFallsAndHospitalizationAssessmentAdminService(params)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, dispatch(getFallsAndHospitalizationAssessmentAdmin())];
                case 3:
                    _a.sent();
                    if (param.type == FallsAndHospitalizationAssessmentAdminStatus.SubmitLater ||
                        param.type == FallsAndHospitalizationAssessmentAdminStatus.Submit ||
                        param.type == FallsAndHospitalizationAssessmentAdminStatus.Reset) {
                        dispatch(getPaginationDataIsolated(function () { return getFallsAndHospitalizationAssessmentHistoryAdmin(); }, PAGINATION_LIMIT.assessmentHistory, '', 1, getAssessmentHistoryAdminSuccess, getAssessmentHistoryAdminFail, '', ''));
                    }
                    dispatch(hideApplicationLoader());
                    dispatch(openOverlayDialog({
                        type: DIALOG_TYPES.SUCCESS,
                        firstMessage: fetchSuccessMessage(param.type),
                    }));
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_3));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
var fetchSuccessMessage = function (type) {
    var submitMessage = "Falls and Hospitalization Assessment questions are submitted successfully";
    var saveMessage = "Falls and Hospitalization Assessment updates are saved successfully";
    var resetMessage = "Falls and Hospitalization Assessment has been reset";
    var submitLater = "The latest Falls and Hospitalization Assessment questions are submitted successfully";
    switch (type) {
        case FallsAndHospitalizationAssessmentAdminStatus.Save:
            return saveMessage;
        case FallsAndHospitalizationAssessmentAdminStatus.Submit:
            return submitMessage;
        case FallsAndHospitalizationAssessmentAdminStatus.SubmitLater:
            return submitLater;
        case FallsAndHospitalizationAssessmentAdminStatus.Reset:
            return resetMessage;
        default:
            return '';
    }
};
