import { VITAL_CONDITION_CLASSNAME } from 'globals/enums';
import { makeStyles } from 'tss-react/mui';
export var ResidentDetailsCardStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        userCardOuterContainer: {
            background: theme.palette.common.white,
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.33)',
            borderRadius: '16px',
            width: '235px',
            height: 'fit-content',
            '@media (max-width: 1919px)': {
                width: '195px',
            },
        },
        userCardContainer: {
            padding: '11px',
        },
        titleContainer: {
            alignItems: 'center',
        },
        icon: {
            height: '18px',
            width: '18px',
        },
        image: {
            height: '100%',
            width: '100%',
        },
        title: {
            color: theme.palette.customColor.titleBlack,
        },
        conditionContainer: (_a = {
                width: 'calc(100% - 30px)',
                padding: '0 10px',
                height: '60px',
                margin: '12px 15px 20px 15px',
                borderRadius: '10px',
                border: "solid 4px ".concat(theme.palette.customColor.borderGrey),
                backgroundColor: theme.palette.common.white,
                justifyContent: 'center',
                alignItems: 'center'
            },
            _a["&.".concat(VITAL_CONDITION_CLASSNAME.GREY)] = {
                borderColor: theme.palette.customColor.disabledRemove,
            },
            _a["&.".concat(VITAL_CONDITION_CLASSNAME.RED)] = {
                borderColor: theme.palette.error.main,
            },
            _a["&.".concat(VITAL_CONDITION_CLASSNAME.GREEN)] = {
                borderColor: theme.palette.success.main,
            },
            _a["&.".concat(VITAL_CONDITION_CLASSNAME.YELLOW)] = {
                borderColor: theme.palette.zoneGold.main,
            },
            _a['&.isResident'] = {
                width: 'calc(100% - 80px)',
                margin: '12px 40px 20px 40px',
            },
            _a['@media (max-width: 1919px)'] = {
                '&.isResident': {
                    width: 'calc(100% - 40px)',
                    margin: '12px 20px 20px 20px',
                },
            },
            _a),
        condition: {
            color: theme.palette.customColor.titleBlack,
            fontSize: '30px',
            fontWeight: 'normal',
            '@media (max-width: 1919px)': {
                fontSize: '26px',
            },
        },
        value: {
            color: theme.palette.customColor.titleBlack,
            fontWeight: 'normal',
        },
        valueTitle: {
            span: {
                color: theme.palette.customColor.titleBlack,
                '@media (max-width: 1920px)': {
                    fontSize: '16px',
                },
            },
        },
        view: {
            height: '36px',
            backgroundColor: theme.palette.customColor.primaryGreen,
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            justifyContent: 'end',
            alignItems: 'center',
            paddingRight: '22px',
            '& p': {
                textDecoration: 'underline',
                color: theme.palette.common.white,
                cursor: 'pointer',
            },
        },
    });
});
