var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import { constructName } from 'globals/global.functions';
import { DATE_FORMAT, DIALOG_TYPES } from 'globals/global.constants';
import { getCareGiver, openDialog, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { getWellnessPlanService, updateWellnessPlanService, } from 'services/wellnessPlanService/wellnessPlan.service';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { GoalStatus } from 'globals/enums';
export var GET_WELLNESS_PLAN = 'GET_WELLNESS_PLAN';
export var RESET_WELLNESS_PLAN = 'RESET_WELLNESS_PLAN';
export var getWellnessPlan = function (customerID, versionNo) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var params, wellnessPlan, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                params = {
                    customer_id: customerID,
                    version: versionNo || '',
                };
                return [4 /*yield*/, getWellnessPlanService(params)];
            case 2:
                wellnessPlan = _a.sent();
                if (wellnessPlan !== null) {
                    dispatch({
                        type: GET_WELLNESS_PLAN,
                        payload: __assign({}, wellnessPlan),
                    });
                }
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_1));
                return [2 /*return*/];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var getSeniorCareGiver = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var list, caregiverList, _a, user_id, name, seniorList;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                list = [];
                return [4 /*yield*/, dispatch(getCareGiver())];
            case 1:
                caregiverList = _b.sent();
                _a = getState().common.seniorDetail.minimalInfo, user_id = _a.user_id, name = _a.name;
                seniorList = {
                    value: user_id,
                    label: "".concat(name.first_name, " ").concat(name.last_name, " (Senior)"),
                };
                list.push(seniorList);
                caregiverList === null || caregiverList === void 0 ? void 0 : caregiverList.forEach(function (data) {
                    list.push({
                        value: data.id,
                        label: "".concat(data.name.firstName, " ").concat(data.name.lastName, " (Caregiver)"),
                        data: data,
                    });
                });
                return [2 /*return*/, list];
        }
    });
}); }; };
export var updateWellnessPlan = function (leftField, memberPriority, challenges, isNextVersion, selectedSeniorCareGiver) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var wellnessState, careAgentName, careAgentId, params, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    wellnessState = getState().wellnessPlan;
                    careAgentName = getState().auth.userName;
                    careAgentId = getState().auth.userId;
                    if (!isNextVersion) {
                        return [2 /*return*/];
                    }
                    dispatch(showApplicationLoader());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    params = {
                        customer_id: selectedSeniorCareGiver.value,
                        customer_name: selectedSeniorCareGiver.label,
                        role: 'senior',
                        wellness_plan_date_started: wellnessState.dateStarted || moment().format(DATE_FORMAT),
                        last_updated_date: moment().format(DATE_FORMAT),
                        last_updated_by: "".concat(careAgentName.first_name, " ").concat(careAgentName.last_name),
                        careagent_id: careAgentId,
                        last_version: wellnessState.currentVersion || '0',
                        current_version: isNextVersion
                            ? "".concat(parseInt(wellnessState.currentVersion || 0) + 1)
                            : wellnessState.currentVersion,
                        wellness_plan_details: {
                            situation: leftField.situation.value,
                            background: leftField.background.value,
                            assessment: leftField.assessment.value,
                            recommendations: leftField.recommendation.value,
                            member_priorities: memberPriority.map(function (data) {
                                return { seq: data.seq, value: data.value };
                            }),
                            challenges: challenges.map(function (data) {
                                return { seq: data.seq, value: data.value };
                            }),
                        },
                    };
                    return [4 /*yield*/, updateWellnessPlanService(params)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, dispatch(getWellnessPlan(selectedSeniorCareGiver.value))];
                case 3:
                    _a.sent();
                    dispatch(openOverlayDialog({
                        type: DIALOG_TYPES.SUCCESS,
                        firstMessage: "Wellness Plan has been successfully saved",
                    }));
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_2));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var openResourcesDialog = function (rowData, dispatchContext) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, goals, wellnessPlan, auth, seniorName, userName, fullName, _b, role, name, goalsRowsData, goalData, existingResourcesData, isDisabled, resourcesData;
        return __generator(this, function (_c) {
            _a = getState(), goals = _a.goals, wellnessPlan = _a.wellnessPlan, auth = _a.auth;
            seniorName = wellnessPlan.seniorName;
            userName = auth.userName;
            fullName = constructName(userName.first_name, userName.middle_name, userName.last_name);
            _b = seniorName.split(' - '), role = _b[0], name = _b[1];
            goalsRowsData = goals.goalsRowsData;
            goalData = goalsRowsData.filter(function (goal) { return goal.id === rowData.id; })[0];
            existingResourcesData = [];
            if (goalData && goalData.resource && goalData.resource.length) {
                existingResourcesData = goalData.resource;
            }
            isDisabled = Boolean(!wellnessPlan.isLatestVersion ||
                rowData.status === GoalStatus.Completed ||
                rowData.status === GoalStatus.Cancelled);
            resourcesData = {
                careagentId: wellnessPlan.careagentId,
                currentVersion: wellnessPlan.currentVersion,
                seniorId: wellnessPlan.seniorId,
                role: role,
                name: name,
                existingResourcesData: existingResourcesData,
                goalData: rowData,
                dispatchGoalsContext: dispatchContext,
                isDisabled: isDisabled,
                fullName: fullName,
            };
            dispatch(openDialog({
                type: DIALOG_TYPES.RESOURCES,
                dialogTitle: 'Resources',
                data: resourcesData,
            }));
            return [2 /*return*/];
        });
    }); };
};
