import { CHART_ZONES } from 'globals/enums';
/**
 * @description function to get average score & target from data & zonestate
 * @function getScoreAndTarget
 * @param zones selected zone state
 * @param data all zones data
 * @returns {{score: number; target: number;}} {score, target}
 */
export var getScoreAndTarget = function (zones, data) {
    if (data) {
        var scoreCount_1 = 0;
        var targetCount_1 = 0;
        var target_1 = 0;
        var score_1 = 0;
        Object.values(CHART_ZONES).forEach(function (zone) {
            var _a, _b, _c, _d;
            if (zones[zone]) {
                if ((_a = data === null || data === void 0 ? void 0 : data[zone]) === null || _a === void 0 ? void 0 : _a.target) {
                    targetCount_1 += 1;
                    target_1 += (_b = data === null || data === void 0 ? void 0 : data[zone]) === null || _b === void 0 ? void 0 : _b.target;
                }
                if ((_c = data === null || data === void 0 ? void 0 : data[zone]) === null || _c === void 0 ? void 0 : _c.score) {
                    scoreCount_1 += 1;
                    score_1 += (_d = data === null || data === void 0 ? void 0 : data[zone]) === null || _d === void 0 ? void 0 : _d.score;
                }
            }
        });
        var avgTarget = Math.ceil(targetCount_1 ? target_1 / targetCount_1 : target_1);
        var avgScore = Math.ceil(scoreCount_1 ? score_1 / scoreCount_1 : score_1);
        return { score: avgScore, target: avgTarget };
    }
    return { score: 0, target: 0 };
};
/**
 * @description get ActivitiChart Tick Value from Activity Target & Data
 * @function getActivityChartTickValues
 * @param {IZoneGraphData} activityData
 * @param {number} activityTarget
 * @returns {number[]} tickValues array
 */
export var getActivityChartTickValues = function (activityData, activityTarget, diff) {
    if (diff === void 0) { diff = 5000; }
    var max = activityTarget > 50000 ? activityTarget : 50000;
    Object.values(activityData).forEach(function (data) {
        data === null || data === void 0 ? void 0 : data.forEach(function (record) {
            if (record.y && record.y > max)
                max = record.y;
        });
    });
    var tickCount = Math.ceil(max / diff) + 1;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * diff; });
    return tickValues;
};
/**
 * @description get FallChart Tick Value Data
 * @function getFallChartTickValues
 * @param {IZoneGraphData} fallData
 * @returns {number[]} tickValues array
 */
export var getFallChartTickValues = function (fallData) {
    var max = 20;
    Object.values(fallData).forEach(function (data) {
        data === null || data === void 0 ? void 0 : data.forEach(function (record) {
            if (record.y && record.y > max)
                max = record.y;
        });
    });
    var tickCount = Math.ceil(max / 2) + 1;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * 2; });
    return tickValues;
};
/**
 * @description get LOSChart Tick Value Data
 * @function getLOSChartTickValues
 * @returns {number[]} tickValues array
 */
export var getLOSChartTickValues = function () {
    var tickCount = 11;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * 3; });
    return tickValues;
};
