var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Grid, Box, Typography, Button, useTheme } from '@mui/material';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import GraphAPI from 'utilities';
import { printStartEndTime, getTimestamp, getCurrentSenior, } from 'globals/global.functions';
import { Card } from 'common/components/Card';
import { ActivityChart } from './ActivityChart.component';
import { activityStyle } from './Activity.style';
import globalUseStyles from 'config/global.styles';
import { InputFields } from 'common/InputFields';
import { DIALOG_TYPES, activityGoalOptions } from 'globals/global.constants';
import ActivityCard from 'common/ActivityCard/ActivityCard.component';
import { ACTIVITY_CARD_VARIANT } from 'globals/enums';
import CircularGraph from 'common/CircularGraph/CircularGraph.component';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { useMutation } from 'utilities/react-query';
import { getActivityGoalService, postActivityGoalService, } from 'services/activityGoalService/activityGoal.service';
import { fetchSeniorDetail, getSeniorFullName, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
var ActivityComponent = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useAppSelector(function (state) { return state.wellnessDashboard; }), currentState = _j.currentState, startTime = _j.startTime, endTime = _j.endTime, reRender = _j.reRender;
    var classes = activityStyle().classes;
    var globalClasses = globalUseStyles().classes;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var fullName = dispatch(getSeniorFullName());
    var _k = useState({
        loading: true,
        subTitle: '',
        noRecordFound: false,
    }), config = _k[0], setConfig = _k[1];
    var _l = useState([]), activityChartData = _l[0], setActivityChartData = _l[1];
    var _m = useState(0), activityScore = _m[0], setActivityScore = _m[1];
    var _o = useState(0), targetScore = _o[0], setTargetScore = _o[1];
    var _p = useState(0), activityPercentage = _p[0], setActivityPercentage = _p[1];
    var _q = useState({
        moderate: 0,
        intense: 0,
        moderateGoal: 120,
        intenseGoal: 20,
        moderatePercentage: '0',
        intensePercentage: '0',
    }), totalDuration = _q[0], setTotalDuration = _q[1];
    var _r = useState(40000), activityGoal = _r[0], setActivityGoal = _r[1];
    var _s = useState(false), isEditActivityGoal = _s[0], setIsEditActivityGoal = _s[1];
    var getActivityGoalMutation = useMutation({
        mutationFn: function () {
            dispatch(showApplicationLoader());
            var senior = getCurrentSenior();
            return getActivityGoalService({
                senior_id: senior.seniorID,
                account_id: senior.accountID,
            });
        },
        onSuccess: function (goal) {
            setActivityGoal(goal || 40000);
            setIsEditActivityGoal(false);
            dispatch(hideApplicationLoader());
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var postActivityGoalMutation = useMutation({
        mutationFn: function (goal) {
            dispatch(showApplicationLoader());
            var senior = getCurrentSenior();
            return postActivityGoalService({
                senior_id: senior.seniorID,
                account_id: senior.accountID,
                goal: goal,
            });
        },
        onSuccess: function () {
            getActivityScore();
            getActualActivity();
            getActivityGoalMutation.mutate();
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: "Activity Goal is updated successfully for ".concat(fullName),
            }));
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    useEffect(function () {
        if (startTime && endTime && reRender) {
            printStartEndTime(startTime, endTime);
            setConfig(__assign(__assign({}, config), { loading: true, noRecordFound: false, subTitle: '' }));
            getActualActivity();
            getActivityScore();
            getActivityGoalMutation.mutate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    var getActivityScore = function () {
        GraphAPI.fetchActivityScore(startTime, endTime).then(function (res) {
            setActivityScore(Math.floor(get(res, "data.".concat(startTime, ".activity_score"), 0)));
            setTargetScore(Math.floor(get(res, 'target_score', 0)));
            setActivityPercentage(Math.round(get(res, 'goal_percentage', 0)));
        });
    };
    var getActualActivity = function () {
        GraphAPI.fetchActivitySeries(startTime, endTime).then(function (res) {
            if (isEmpty(res)) {
                setConfig(__assign(__assign({}, config), { loading: false, noRecordFound: true }));
                return;
            }
            var high = [];
            var medium = [];
            var yDomain = [];
            var totalModerateDuration = 0;
            var totalIntenseDuration = 0;
            Object.entries(res).forEach(function (data) {
                var highActivity = get(data[1], 'high_activity', 0);
                var mediumActivity = get(data[1], 'medium_activity', 0);
                var activityDuration = get(data[1], 'activity_duration', 0);
                var xPlot = moment(getTimestamp(parseInt(data[0]))).format();
                var yPlotHigh = (activityDuration * (highActivity / 100)) / 60;
                var yPlotMedium = (activityDuration * (mediumActivity / 100)) / 60;
                yDomain.push(yPlotHigh + yPlotMedium);
                high.push({
                    x: xPlot,
                    y: yPlotHigh,
                });
                totalIntenseDuration += (activityDuration / 60) * (highActivity / 100);
                medium.push({
                    x: xPlot,
                    y: yPlotMedium,
                });
                totalModerateDuration +=
                    (activityDuration / 60) * (mediumActivity / 100);
            });
            var moderateHour = Math.floor(totalModerateDuration / 60);
            var moderateMin = Math.floor(totalModerateDuration) - moderateHour * 60;
            var intenseHour = Math.floor(totalIntenseDuration / 60);
            var intenseMin = Math.floor(totalIntenseDuration) - intenseHour * 60;
            var moderatePer = Math.round(((moderateHour * 60 + moderateMin) / totalDuration.moderateGoal) * 100);
            var intensePer = Math.round(((intenseHour * 60 + intenseMin) / totalDuration.intenseGoal) * 100);
            setTotalDuration(__assign(__assign({}, totalDuration), { moderate: [
                    moderateHour ? "".concat(moderateHour, "h") : '',
                    moderateMin ? "".concat(moderateMin, "m") : '',
                ].join(' '), intense: [
                    intenseHour ? "".concat(intenseHour, "h") : '',
                    intenseMin ? "".concat(intenseMin, "m") : '',
                ].join(' '), moderatePercentage: "".concat(moderatePer), intensePercentage: "".concat(intensePer) }));
            setConfig(__assign(__assign({}, config), { loading: false, noRecordFound: totalModerateDuration || totalIntenseDuration ? false : true, maxDomain: Math.max.apply(Math, yDomain) }));
            setActivityChartData([medium, high]);
        });
    };
    var getTitle = function () {
        switch (currentState) {
            case 'day':
                return 'Goal';
            case 'week':
                return 'Week Average';
            case 'month':
                return 'Month Average';
        }
    };
    var handleSetActivityGoal = React.useCallback(function () {
        postActivityGoalMutation.mutate(activityGoal);
    }, [activityGoal, postActivityGoalMutation]);
    useEffect(function () {
        dispatch(fetchSeniorDetail());
    }, []);
    return (_jsxs(Grid, { spacing: 2, container: true, "data-testid": 'activity-container', children: [_jsxs(Grid, { item: true, sm: 4.5, flexDirection: 'column', container: true, spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(Card, { isLoading: config.loading, cardContentStyle: {
                                padding: '5px 16px 8px 16px',
                            }, children: _jsxs(Box, { display: 'flex', style: {
                                    justifyContent: 'space-between',
                                    gap: '1rem',
                                    width: '100%',
                                }, children: [_jsxs(Box, { display: 'flex', alignItems: 'center', gap: '1rem', children: [_jsx(Typography, { className: classes.activityGoalLabel, children: "Set Activity Goal" }), _jsx(Box, { width: '140px', children: _jsx(InputFields, { menu: true, inputProps: {
                                                        name: 'activity_goal',
                                                        disabled: !isEditActivityGoal,
                                                    }, menuItems: activityGoalOptions, eventProps: {
                                                        onChange: function (e) { return setActivityGoal(e.target.value); },
                                                        value: activityGoal,
                                                        'data-testid': 'activity-goal-input',
                                                    } }) })] }), _jsx(Button, { variant: 'contained', color: 'primary', className: globalClasses.smallButton, style: { minWidth: '120px' }, onClick: isEditActivityGoal
                                            ? handleSetActivityGoal
                                            : function () { return setIsEditActivityGoal(true); }, "data-testid": 'activity-goal-button', children: isEditActivityGoal ? 'Save' : 'Edit' })] }) }) }), _jsx(Grid, { item: true, children: _jsx(Card, { isLoading: config.loading, noRecordFound: config.noRecordFound, title: getTitle(), stretch: true, children: _jsxs(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', width: '100%', children: [_jsx(Box, { width: '220px', display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(Box, { width: '175px', height: '175px', children: _jsx(CircularGraph, { data: [
                                                    {
                                                        value: totalDuration.intensePercentage,
                                                        color: (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.intenseGreen,
                                                        key: 'intense',
                                                    },
                                                    {
                                                        value: totalDuration.moderatePercentage,
                                                        color: (_d = (_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.moderateGreen,
                                                        key: 'moderate',
                                                    },
                                                    {
                                                        value: activityPercentage,
                                                        color: (_f = (_e = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _e === void 0 ? void 0 : _e.customColor) === null || _f === void 0 ? void 0 : _f.remGreen,
                                                        key: 'score',
                                                    },
                                                ], bgColor: (_h = (_g = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _g === void 0 ? void 0 : _g.customColor) === null || _h === void 0 ? void 0 : _h.bgGrey }) }) }), _jsxs(Box, { display: 'flex', flexDirection: 'column', gap: '1rem', flexGrow: 1, children: [_jsx(ActivityCard, { variant: ACTIVITY_CARD_VARIANT.ACTIVITY, goal: "".concat(activityScore, "/").concat(targetScore), percentage: activityPercentage }), _jsx(ActivityCard, { variant: ACTIVITY_CARD_VARIANT.MODERATE, goal: "".concat(totalDuration.moderate || '0h', "/2h"), percentage: totalDuration.moderatePercentage }), _jsx(ActivityCard, { variant: ACTIVITY_CARD_VARIANT.INTENSE, goal: "".concat(totalDuration.intense || '0m', "/20m"), percentage: totalDuration.intensePercentage })] })] }) }) })] }), _jsx(Grid, { item: true, sm: 7.5, children: _jsx(Card, { noRecordFound: config.noRecordFound, isLoading: config.loading, title: 'Activity', stretch: true, children: _jsx(ActivityChart, { type: currentState, activityChartData: activityChartData, currentState: currentState, maxDomain: config.maxDomain }) }) })] }));
};
export { ActivityComponent };
