var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable max-len */
import { CLEAR_REDUX_STATE, CLOSE_TIMEOUT_MODEL, FETCHING_LOGIN_DETAILS, FETCHING_LOGIN_DETAILS_DONE, LOGOUT_USER, OPEN_TIMEOUT_MODEL, USER_LOGGED_IN, } from './Login.types';
import { showApplicationLoader, hideApplicationLoader, } from '../../../common/ApplicationLoader';
import { showToast } from '../../../common/Toast';
import { API } from '../../../globals/api';
import { injectStorageEvent, saveUserInfo, getCareAgentDetailsParseJWT, getCurrentUserRoleConfig, getCareAgentInfo, parseJwt, getLocalStorage, setLocalStorage, } from 'globals/global.functions';
import { ALERT_MESSAGES, CACHE_EVENTS_AUTO_LOGOUT, LOCAL_STORAGE_KEY, LOGOUT_MESSAGE, USER_SESSION_KEY, } from '../../../globals/global.constants';
import { removeAllEvents } from 'store/eventsReducer/Events.action';
import PusherApp from 'pusher/pusherApp';
import { Roles } from 'globals/enums';
import { postUserMappingService, loginUserService, } from 'services/userService/userService';
import { push } from 'redux-first-history';
import { getPasswordExpiryDate, getPasswordResetWarning, } from 'globals/date.functions';
import { getCorpFacilityUserService } from 'services/coporateAndFacilitiesService/FacilityManagementService/facilitymanagement.service';
export var UPDATE_PASSWORD_EXPIRY_WARNING = 'UPDATE_PASSWORD_EXPIRY_WARNING';
export var toggleLogin = function (values) {
    return {
        type: FETCHING_LOGIN_DETAILS_DONE,
        payload: __assign({}, values),
    };
};
export var loginUser = function (values) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var response, decodedJWT, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({ type: FETCHING_LOGIN_DETAILS });
                    return [4 /*yield*/, loginUserService({
                            email: values.email.toLowerCase(),
                            password: values.password,
                        })];
                case 1:
                    response = _a.sent();
                    dispatch(hideApplicationLoader());
                    decodedJWT = parseJwt(response.data[0].jwt);
                    if (decodedJWT.is_first_login && decodedJWT.is_reset_needed) {
                        dispatch(loginWithOtp(values));
                    }
                    else {
                        dispatch(loginWithPassword(response, values));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(toggleLogin({ isAuthenticated: false }));
                    dispatch(showToast('Incorrect Username or Password.', 'error'));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
var loginWithOtp = function (values) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(push("/choose-password?otp=".concat(values.password, "&email=").concat(values.email)));
        return [2 /*return*/];
    });
}); }; };
var loginWithPassword = function (response, values) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var loginUserDetails, _a, roles, secondary_role, user_id, password_created_date, sugar_id, role, error_2, currentUserConfig;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                dispatch(showApplicationLoader());
                loginUserDetails = {
                    email: values.email,
                    accessToken: response.data[0].jwt,
                    refreshToken: response.data[0].refresh_token,
                    userName: {
                        first_name: '',
                        last_name: '',
                        middle_name: '',
                    },
                    userId: '',
                    userRole: [],
                    facility_info: {
                        corporate_name: '',
                        facility_id: '',
                        facility_type: '',
                        facility_name: '',
                        corporate_id: '',
                    },
                    passwordCreatedData: {
                        createdDate: '',
                    },
                };
                saveUserInfo(loginUserDetails);
                _a = getCareAgentDetailsParseJWT(), roles = _a.roles, secondary_role = _a.secondary_role, user_id = _a.user_id, password_created_date = _a.password_created_date, sugar_id = _a.sugar_id;
                role = __spreadArray([roles], secondary_role, true);
                loginUserDetails = __assign(__assign({}, loginUserDetails), { userId: user_id, userRole: role, passwordCreatedData: {
                        createdDate: password_created_date,
                    }, sugarId: sugar_id });
                saveUserInfo(loginUserDetails);
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, dispatch(getUserMappingAction())];
            case 2:
                _b.sent(); // get username and facility details
                return [3 /*break*/, 4];
            case 3:
                error_2 = _b.sent();
                throw new Error(error_2);
            case 4:
                currentUserConfig = getCurrentUserRoleConfig();
                setLocalStorage(USER_SESSION_KEY, 1);
                dispatch(toggleLogin({
                    isAuthenticated: true,
                    roleConfig: currentUserConfig,
                    email: loginUserDetails.email,
                    userId: loginUserDetails.userId,
                    userRole: loginUserDetails.userRole,
                    passwordCreatedData: __assign({}, loginUserDetails.passwordCreatedData),
                }));
                dispatch(showToast('Login Successfull !', 'success'));
                dispatch(hideApplicationLoader());
                dispatch(redirectOnLogin());
                return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to fetch user info mapping
 * @returns void
 */
var getUserMappingAction = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var careAgentDetails, careagentMoreDetails, response, error_3;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _k.trys.push([0, 5, , 6]);
                careAgentDetails = getCareAgentInfo();
                careagentMoreDetails = {};
                if (!careAgentDetails.userRole.includes(Roles.FACILITY_AGENT)) return [3 /*break*/, 2];
                return [4 /*yield*/, getCorpFacilityUserService({
                        limit: 100,
                        offset: 0,
                        agent: 'FACA',
                        id: JSON.stringify([(_a = careAgentDetails.sugarId) !== null && _a !== void 0 ? _a : '']),
                    })];
            case 1:
                response = _k.sent();
                careagentMoreDetails[careAgentDetails.userId] = response.data[0];
                careAgentDetails = __assign(__assign({}, careAgentDetails), { userName: {
                        first_name: ((_b = careagentMoreDetails[careAgentDetails.userId]) === null || _b === void 0 ? void 0 : _b.name.firstName) || '',
                        last_name: ((_c = careagentMoreDetails[careAgentDetails.userId]) === null || _c === void 0 ? void 0 : _c.name.lastName) || '',
                        middle_name: '',
                    }, facility_info: __assign(__assign({}, careAgentDetails.facility_info), { corporate_name: (_d = careagentMoreDetails[careAgentDetails.userId]) === null || _d === void 0 ? void 0 : _d.corporateName, facility_id: (_e = careagentMoreDetails[careAgentDetails.userId]) === null || _e === void 0 ? void 0 : _e.facilityId, facility_type: (_f = careagentMoreDetails[careAgentDetails.userId]) === null || _f === void 0 ? void 0 : _f.facilityType, facility_name: (_g = careagentMoreDetails[careAgentDetails.userId]) === null || _g === void 0 ? void 0 : _g.facilityName, corporate_id: (_h = careagentMoreDetails[careAgentDetails.userId]) === null || _h === void 0 ? void 0 : _h.corporateId }) });
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, postUserMappingService([
                    careAgentDetails.userId,
                ])];
            case 3:
                careagentMoreDetails = _k.sent();
                careAgentDetails = __assign(__assign({}, careAgentDetails), { userName: ((_j = careagentMoreDetails[careAgentDetails.userId]) === null || _j === void 0 ? void 0 : _j.name) || {
                        first_name: '',
                        last_name: '',
                        middle_name: '',
                    } });
                _k.label = 4;
            case 4:
                saveUserInfo(careAgentDetails);
                dispatch(toggleLogin({
                    userName: careAgentDetails.userName,
                    facilityDetails: (careAgentDetails === null || careAgentDetails === void 0 ? void 0 : careAgentDetails.facility_info) || {},
                }));
                return [2 /*return*/];
            case 5:
                error_3 = _k.sent();
                console.log({ error: error_3 });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var onClickLogout = function (message) { return function (dispatch) {
    //check if autosave time is running or not
    var timer = getLocalStorage(LOCAL_STORAGE_KEY.AUTO_SAVE_TIMER);
    if (timer) {
        window.alert(ALERT_MESSAGES.autoSaveLogout);
    }
    else {
        dispatch(logoutUser(message));
    }
}; };
export var logoutUser = function (message, inActiveLogout) {
    if (message === void 0) { message = LOGOUT_MESSAGE; }
    if (inActiveLogout === void 0) { inActiveLogout = false; }
    return function (dispatch) {
        var logoutUserOperations = function () {
            var user = localStorage.getItem('userInfo');
            var userObject = JSON.parse(user);
            var logoutApiData = {};
            if (userObject) {
                logoutApiData = {
                    refresh_token: userObject.refreshToken,
                    email: userObject.email,
                };
            }
            dispatch(showApplicationLoader());
            API({
                url: 'cognito/authentication/logout',
                method: 'post',
                data: JSON.stringify(logoutApiData),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(function () {
                injectStorageEvent(CACHE_EVENTS_AUTO_LOGOUT.SESSION_FLUSH, inActiveLogout);
                dispatch(logoutCache(message));
            })
                .catch(function () {
                localStorage.clear();
                dispatch(logoutCache(message));
            });
        };
        logoutUserOperations();
    };
};
/**
 * @description :-
 *    Logout user from the system and clear all session, storage and redirect user to login page.
 * @param message logout message
 * @returns call back to dispatch actions
 */
export var logoutCache = function (message, type) {
    if (type === void 0) { type = 'success'; }
    return function (dispatch) {
        localStorage.clear();
        var eventPusher = new PusherApp();
        eventPusher.pusherDisconnect();
        dispatch(removeAllEvents());
        dispatch({ type: LOGOUT_USER });
        dispatch(push('/login'));
        dispatch(clearReduxState());
        dispatch(hideApplicationLoader());
        dispatch(showToast(message, type));
    };
};
/**
 * @description action creator to clear redux states
 * @function clearReduxState
 */
export var clearReduxState = function () { return function (dispatch) {
    dispatch({ type: CLEAR_REDUX_STATE });
}; };
/**
 * @description action creator to open the model for all the tabs except parent
 * @param value boolean to show hide action buttons
 */
export var openAutoLogoutModel = function () { return function (dispatch) {
    dispatch({ type: OPEN_TIMEOUT_MODEL });
}; };
/**
 * @description action creator to close the model for all the tabs except parent
 * @param value boolean to show hide action buttons
 */
export var closeAutoLogoutModel = function () { return function (dispatch) {
    dispatch({ type: CLOSE_TIMEOUT_MODEL });
}; };
/**
 * @description action creator to store the user value in redux when user refresh the page
 */
export var userLoggedIn = function () { return function (dispatch) {
    var careAgentInfo = getCareAgentInfo();
    var roleConfig = getCurrentUserRoleConfig();
    var email = careAgentInfo.email, userId = careAgentInfo.userId, userName = careAgentInfo.userName, userRole = careAgentInfo.userRole, passwordCreatedData = careAgentInfo.passwordCreatedData, facility_info = careAgentInfo.facility_info;
    passwordCreatedData = __assign({}, passwordCreatedData);
    dispatch({
        type: USER_LOGGED_IN,
        payload: {
            roleConfig: roleConfig,
            email: email,
            userId: userId,
            userName: userName,
            userRole: userRole,
            passwordCreatedData: passwordCreatedData,
            facilityDetails: facility_info,
        },
    });
}; };
/**
 * @function redirectOnLogin
 * @description action creator to redirect the user after login
 * @param {string} userRole user current role
 * @returns {void}
 */
export var redirectOnLogin = function () { return function (dispatch, getState) {
    var _a = getState().auth, roleConfig = _a.roleConfig, userRole = _a.userRole;
    var hasRole = userRole.every(function (role) {
        return Object.values(Roles).includes(role);
    });
    if (hasRole) {
        var pageToRedirect = roleConfig.defaultPage || '/';
        dispatch(push(pageToRedirect));
    }
    else {
        dispatch(showToast('You do not have permission to access portal.', 'error'));
    }
}; };
/**
 * @description action creator to update the password expiry date and warning day
 * @function updatePasswordExpiryWarning
 */
export var updatePasswordExpiryWarning = function () { return function (dispatch, getState) {
    var _a;
    var passwordExpiryWarningDays = getState().config.passwordExpiryWarningDays;
    var passwordExpiryDays = getState().config.passwordExpiryDays;
    var passwordCreatedDate = (_a = getState().auth.passwordCreatedData) === null || _a === void 0 ? void 0 : _a.createdDate;
    var passwordExpiryDate = getPasswordExpiryDate(passwordCreatedDate, passwordExpiryDays);
    dispatch({
        type: UPDATE_PASSWORD_EXPIRY_WARNING,
        payload: {
            isPasswordResetWarning: getPasswordResetWarning(passwordCreatedDate, passwordExpiryWarningDays),
            passwordExpiryDate: passwordExpiryDate,
        },
    });
}; };
