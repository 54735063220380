import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { InputTextBox } from 'pages/WCPages/SeniorCallScheduler/formFields';
import { goalStyle } from './index.style';
import { useMutation } from 'utilities/react-query';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { getCurrentSenior } from 'globals/global.functions';
import { getSeniorFullName, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { DIALOG_TYPES } from 'globals/global.constants';
import { useAppDispatch } from 'hooks/reduxHooks';
import { getSleepGoalService, postSleepGoalService, } from 'services/sleepGoalService/sleepGoal.service';
import { Card } from 'common/components/Card';
import { RE_RENDER_GRAPH } from 'pages/WCPages/WellnessDashboard/WellnessDashboard.types';
var SleepGoal = function (_a) {
    var width = _a.width, onGoalGetSuccess = _a.onGoalGetSuccess;
    var classes = goalStyle().classes;
    var _b = useState(true), disable = _b[0], setDisable = _b[1];
    var _c = useState(false), callDailySleepApis = _c[0], setCallDailySleepApis = _c[1];
    var _d = useState(80), goal = _d[0], setGoals = _d[1];
    var dispatch = useAppDispatch();
    var fullName = dispatch(getSeniorFullName());
    useEffect(function () {
        getSleepGoal.mutate();
    }, []);
    var onHandleChange = useCallback(function (e) {
        var value = parseInt(e.target.value);
        if (value < 0 || isNaN(value)) {
            setGoals(0);
        }
        else if (value > 100) {
            setGoals(100);
        }
        else {
            setGoals(value);
        }
    }, []);
    var onButtonClick = useCallback(function () {
        if (disable) {
            setDisable(!disable);
        }
        else {
            setCallDailySleepApis(true);
            handleSetSleepGoal(goal);
            setDisable(!disable);
        }
    }, [disable, goal]);
    var getSleepGoal = useMutation({
        mutationFn: function () {
            dispatch(showApplicationLoader());
            var senior = getCurrentSenior();
            return getSleepGoalService({
                senior_id: senior.seniorID,
                account_id: senior.accountID,
            });
        },
        onSuccess: function (goal) {
            if (callDailySleepApis) {
                onGoalGetSuccess();
            }
            setCallDailySleepApis(false);
            setGoals(goal);
            dispatch(hideApplicationLoader());
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var postSleepGoal = useMutation({
        mutationFn: function (goal) {
            dispatch(showApplicationLoader());
            var senior = getCurrentSenior();
            return postSleepGoalService({
                senior_id: senior.seniorID,
                account_id: senior.accountID,
                sleep_score_goal: goal,
            });
        },
        onSuccess: function () {
            dispatch(hideApplicationLoader());
            getSleepGoal.mutate();
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: "Sleep Goal is updated successfully for ".concat(fullName),
            }));
            dispatch({ type: RE_RENDER_GRAPH, value: true });
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var handleSetSleepGoal = React.useCallback(function (value) {
        postSleepGoal.mutate(value);
    }, [postSleepGoal, goal]);
    return (_jsxs(Card, { children: [_jsx(Typography, { children: "Set Sleep Goal" }), _jsxs(Box, { style: { display: 'flex', columnGap: '12px' }, children: [_jsx(Box, { children: getSleepGoal.isLoading ? (_jsx(Box, { margin: '0 30px', children: _jsx(CircularProgress, {}) })) : (_jsx(InputTextBox, { required: true, className: classes.input, onChange: onHandleChange, disabled: disable, inputProps: { value: goal } })) }), _jsx(Button, { className: classes.button, style: { width: '119px' }, variant: 'contained', color: 'primary', size: 'large', "data-testid": 'sleep-goal-button', onClick: onButtonClick, children: disable ? 'Edit' : 'Save' })] })] }));
};
export default SleepGoal;
