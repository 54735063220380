var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { closeAllActionNotification } from './../../../../store/eventsReducer/Events.action';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { getCareInsightTransactionService } from 'services/careInsightService/insightSummary.service';
import { convertStartDateInUTCTz, convertEndDateInUTCTz, getClientTimezone, } from 'globals/date.functions';
import { DATE_FORMAT } from 'globals/global.constants';
import { getCareInsightHistory } from '../SeniorCareInsight.action';
import { showToast } from 'common/Toast';
import { EventViewState } from 'globals/enums';
export var GET_INSIGHT_HISTORY = 'GET_INSIGHT_HISTORY';
export var END_PAGINATION = 'END_PAGINATION';
export var RESET_INSIGHT_HISTORY = 'RESET_INSIGHT_HISTORY';
export var RESET_PAGINATION = 'RESET_PAGINATION';
export var UPDATE_SUBHISTORY = 'UPDATE_SUBHISTORY';
export var EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY = 'EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY';
export var getInsightHistory = function (offset, limit) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, user_id, account_id, created_date, timezone, start_time, end_time, start_timestamp, end_timestamp, param, response, historyDataMappedNotificationData, err_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                _a = getState().common.seniorDetail.minimalInfo, user_id = _a.user_id, account_id = _a.account_id, created_date = _a.created_date;
                timezone = getClientTimezone();
                start_time = convertStartDateInUTCTz(moment(created_date).format(DATE_FORMAT), timezone);
                end_time = convertEndDateInUTCTz(moment().format(DATE_FORMAT), timezone);
                start_timestamp = parseInt(moment(start_time).format('x')) * 1000000;
                end_timestamp = parseInt(moment(end_time).format('x')) * 1000000;
                param = {
                    start_timestamp: start_timestamp,
                    end_timestamp: end_timestamp,
                    senior_id: user_id,
                    account_id: account_id,
                    desc: true,
                    offset: offset,
                    limit: limit,
                };
                return [4 /*yield*/, dispatch(getCareInsightHistory(param))];
            case 1:
                response = _b.sent();
                if (isEmpty(response.results)) {
                    dispatch({ type: END_PAGINATION });
                }
                historyDataMappedNotificationData = dispatch(mapCareInsightNotifyData(response.results));
                dispatch({
                    type: GET_INSIGHT_HISTORY,
                    payload: {
                        careInsightHistory: historyDataMappedNotificationData,
                    },
                });
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 2:
                err_1 = _b.sent();
                dispatch(showToast(err_1.message, 'error'));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var getCareInsightTransaction = function (id) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var user_id, careInsightSubHistory, params, response, subHistoryData, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                user_id = getState().common.seniorDetail.minimalInfo.user_id;
                careInsightSubHistory = getState().messageManager.careInsightSubHistory;
                if (careInsightSubHistory[id]) {
                    return [2 /*return*/];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                dispatch(showApplicationLoader());
                params = {
                    care_insight_id: id,
                    senior_id: user_id,
                };
                return [4 /*yield*/, getCareInsightTransactionService(params)];
            case 2:
                response = _b.sent();
                subHistoryData = (_a = {},
                    _a[id] = __spreadArray([], response, true).sort(function (a, b) {
                        return (+moment(b.dateUpdated).format('X') -
                            +moment(a.dateUpdated).format('X'));
                    }),
                    _a);
                dispatch({
                    type: UPDATE_SUBHISTORY,
                    payload: {
                        subHistoryData: subHistoryData,
                    },
                });
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 3:
                error_1 = _b.sent();
                return [2 /*return*/, error_1];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var mapCareInsightNotifyData = function (careInsightHistoryData) { return function (dispatch, getState) {
    var user_id = getState().common.seniorDetail.minimalInfo.user_id;
    var careInsightEvents = __assign(__assign({}, getState().events.alert), getState().events.summary);
    var isActionNotification = Object.values(careInsightEvents).some(function (data) {
        return data.viewState === EventViewState.ActionNotification &&
            data.seniorId === user_id;
    });
    if (isActionNotification) {
        var mappedData = careInsightHistoryData.map(function (data) {
            if (careInsightEvents["".concat(user_id, "-").concat(data.careInsightId)] &&
                careInsightEvents["".concat(user_id, "-").concat(data.careInsightId)].viewState ===
                    EventViewState.ActionNotification) {
                data.notify = true;
                return data;
            }
            return data;
        });
        dispatch(closeAllActionNotification());
        return mappedData;
    }
    else {
        return careInsightHistoryData;
    }
}; };
export var expandCareInsight = function (id) { return function (dispatch, getState) {
    var careInsightHistory = getState().messageManager.careInsightHistory;
    var updatedData = careInsightHistory.map(function (data) {
        if (data.careInsightId === id) {
            data.isExpand = true;
            data.notify = false;
            return data;
        }
        return data;
    });
    dispatch({
        type: EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY,
        payload: {
            careInsightHistory: updatedData,
        },
    });
}; };
export var collapseCareInsight = function (id) { return function (dispatch, getState) {
    var careInsightHistory = getState().messageManager.careInsightHistory;
    var updatedData = careInsightHistory.map(function (data) {
        if (data.careInsightId === id) {
            data.isExpand = !data.isExpand;
            return data;
        }
        return data;
    });
    dispatch({
        type: EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY,
        payload: {
            careInsightHistory: updatedData,
        },
    });
}; };
