var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Box, Grid, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import { getTimestamp, getHourMin, printStartEndTime, } from 'globals/global.functions';
import { Card } from 'common/components/Card';
import { CircularProgress } from 'common/CircularProgress';
import { addDate, convertMiliSecondsToNanoSeconds, getCurrentWeek, summaryDetails, } from 'globals/date.functions';
import { getSleepDaily } from '../../sleep.action';
import { sleepStyle } from '../../Sleep.style';
var SleepDuration = function () {
    var theme = useTheme();
    var _a = useAppSelector(function (state) { return state.wellnessDashboard; }), endTimeT = _a.endTime, reRender = _a.reRender;
    var _b = getCurrentWeek(endTimeT), startTime = _b.startTime, endTime = _b.endTime;
    var dispatch = useAppDispatch();
    var classes = sleepStyle().classes;
    var _c = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), summary = _c[0], setSummary = _c[1];
    var _d = useState([]), duration = _d[0], setDuration = _d[1];
    var _e = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), score = _e[0], setScore = _e[1];
    var _f = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), graph = _f[0], setGraph = _f[1];
    useEffect(function () {
        if (startTime && endTime && reRender) {
            printStartEndTime(startTime, endTime);
            setSummary(__assign(__assign({}, summary), { loading: true, subTitle: '' }));
            setScore(__assign(__assign({}, score), { loading: true }));
            setGraph(__assign(__assign({}, graph), { loading: true }));
            getWeekSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    var getWeekSummary = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, endTimePlusOne, resGraph, data, summaryList, summaryData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepDaily(startTime, endTime))];
                case 1:
                    res = _b.sent();
                    endTimePlusOne = (_a = addDate(getTimestamp(endTimeT), 1, 'days')) === null || _a === void 0 ? void 0 : _a.format('x');
                    return [4 /*yield*/, dispatch(getSleepDaily(startTime, convertMiliSecondsToNanoSeconds(endTimePlusOne)))];
                case 2:
                    resGraph = _b.sent();
                    data = get(res, 'summary');
                    summaryList = get(resGraph, 'summaryList');
                    if (isEmpty(data)) {
                        setSummary(__assign(__assign({}, summary), { loading: false, notFound: true }));
                        setGraph(__assign(__assign({}, graph), { loading: false, notFound: true }));
                        setScore(__assign(__assign({}, score), { loading: false, notFound: true }));
                        return [2 /*return*/];
                    }
                    summaryData = summaryDetails(summaryList);
                    setGraph(__assign(__assign({}, graph), { data: summaryData, loading: false, notFound: false }));
                    setSummary(__assign(__assign({}, summary), { data: res, loading: false, notFound: false }));
                    updateScore(get(res, 'summary'));
                    updateDuration(get(res, 'summary'));
                    return [2 /*return*/];
            }
        });
    }); };
    var updateScore = function (weekScore) {
        weekScore.sort(function (x, y) { return x.time - y.time; });
        var allScore = [];
        weekScore.forEach(function (data) {
            var timestamp = getTimestamp(data.time);
            allScore.push({
                week: moment(timestamp).format('ddd').charAt(0),
                score: data.sleep_score,
            });
        });
        setScore(__assign(__assign({}, score), { data: allScore, loading: false, notFound: false }));
    };
    var updateDuration = function (weekDuration) {
        weekDuration.sort(function (x, y) { return x.time - y.time; });
        var allDuration = [];
        weekDuration.forEach(function (data) {
            var timestamp = getTimestamp(data.time);
            allDuration.push({
                week: moment(timestamp).format('dddd'),
                time: getHourMin(get(data, 'total_timeinbed')),
            });
        });
        setDuration(allDuration);
    };
    return (_jsx(_Fragment, { children: _jsxs(Grid, { item: true, display: 'flex', flexDirection: 'column', "data-testid": 'sleep-week-component', children: [_jsx(Card, { noRecordFound: score.notFound, isLoading: score.loading, style: { borderRadius: 0, boxShadow: 'none' }, cardContentStyle: {
                        paddingBottom: 0,
                    }, children: _jsx(Box, { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', style: { padding: 0 }, children: score.data.map(function (data) {
                            return (_jsxs(Box, { className: classes.sleepWeekScore, children: [_jsx("strong", { children: data.week }), _jsx(CircularProgress, { value: data.score })] }, data.week));
                        }) }) }), _jsx(Card, { mt: 2, title: 'Duration In Bed', noRecordFound: score.notFound, isLoading: score.loading, stretch: true, style: { borderRadius: 0, boxShadow: 'none', marginBottom: 15 }, cardContentStyle: {
                        padding: '0 16px',
                    }, children: _jsx(Box, { className: classes.sleepScoreListContainer, children: duration.map(function (data) {
                            return (_jsxs(Box, { className: classes.sleepScoreList, children: [_jsxs(Box, { className: classes.sleepScoreListItem, children: [_jsx(Box, { className: classes.sleepScoreListIcon }), data.week] }), _jsx(Box, { children: data.time })] }, data.week));
                        }) }) })] }) }));
};
export { SleepDuration };
