var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showError } from './../../../../store/commonReducer/common.action';
/* eslint-disable no-console */
import { getCareAgentInfo } from 'globals/global.functions';
import { updateInsightStatus } from './../../../../store/eventsReducer/Events.action';
/* eslint-disable max-len */
import { isEmpty } from 'lodash';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { CareInsightStatus, CareInsightTypes } from 'globals/enums';
import { postAlertDialog } from 'store/eventsReducer/Alerts.action';
import { getAllCareInsightEventsService, postSummaryMessageService, } from 'services/careInsightService/insightSummary.service';
import { PAGINATION_LIMIT } from 'globals/global.constants';
export var GET_CARE_INSIGHT_REVIEW = 'GET_CARE_INSIGHT_REVIEW';
export var RESET_CARE_INSIGHT_REVIEWS = 'RESET_CARE_INSIGHT_REVIEWS';
export var CARE_INSIGHT_REVIEWS_RESET_PAGINATION = 'CARE_INSIGHT_REVIEWS_RESET_PAGINATION';
export var CARE_INSIGHT_REVIEWS_END_PAGINATION = 'CARE_INSIGHT_REVIEWS_END_PAGINATION';
/**
 * @functions getAdminCareInsight
 * @description action creator to fetch the care insight on admin dashboard
 * @returns void
 */
export var getAdminCareInsight = function (offset, limit) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var payload, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                payload = {
                    desc: true,
                    limit: limit,
                    offset: offset,
                    status: CareInsightStatus.SentForApproval,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAllCareInsightEventsService(payload)];
            case 2:
                response = _a.sent();
                if (isEmpty(response)) {
                    dispatch({ type: CARE_INSIGHT_REVIEWS_END_PAGINATION });
                }
                dispatch({
                    type: GET_CARE_INSIGHT_REVIEW,
                    payload: { data: response },
                });
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                dispatch(showError(error_1));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function approveCareInsight
 * @description action creator to approve care insight.
 * @param {string} insightId
 * @param {string} msg
 * @param {string} seniorId
 * @param {string} type
 */
export var approveCareInsight = function (insightId, msg, seniorId, type, facilityId) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var careAgentInfo, CareInsightReviewData, careInsight, status, payload, payload;
        return __generator(this, function (_a) {
            careAgentInfo = getCareAgentInfo();
            CareInsightReviewData = getState().careInsightReview.allCareInsightReviews;
            careInsight = CareInsightReviewData.find(function (data) {
                return data.careInsightId === insightId;
            });
            status = careInsight.message !== msg
                ? CareInsightStatus.ApprovedWithEdit
                : CareInsightStatus.Approved;
            if (type === CareInsightTypes.Summary ||
                type === CareInsightTypes.Facility_Summary) {
                payload = {
                    senior_id: seniorId,
                    message: msg,
                    insight_type: type,
                    status: status,
                    care_insight_id: insightId,
                    updated_by: careAgentInfo.userName.first_name,
                };
                if (type === CareInsightTypes.Facility_Summary) {
                    payload.facility_id = facilityId;
                }
                dispatch(approveSummary(payload));
            }
            else {
                payload = {
                    careInsightId: insightId,
                    status: status,
                    message: msg,
                    updated_by: careAgentInfo.userName.first_name,
                };
                dispatch(approveAlert(payload));
            }
            return [2 /*return*/];
        });
    }); };
};
/**
 * @function approveAlert
 * @description action to approve care insight alert
 * @param {IUpdateCareInsightStatusParams} payload payload of the API
 */
export var approveAlert = function (payload) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch({ type: CARE_INSIGHT_REVIEWS_END_PAGINATION });
                return [4 /*yield*/, dispatch(postAlertDialog(payload.careInsightId, payload.status, payload.message))];
            case 1:
                _a.sent();
                dispatch(resetCareInsightReviewData());
                dispatch(getAdminCareInsight(0, PAGINATION_LIMIT.adminCareInsightReview));
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                dispatch(showError(error_2));
                console.log(error_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function approveSummary
 * @description action creator to approve care insight summary
 * @param payload payload of the API
 * @returns
 */
export var approveSummary = function (payload) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch({ type: CARE_INSIGHT_REVIEWS_END_PAGINATION });
                return [4 /*yield*/, postSummaryMessageService(payload)];
            case 1:
                _a.sent();
                dispatch(resetCareInsightReviewData());
                dispatch(getAdminCareInsight(0, PAGINATION_LIMIT.adminCareInsightReview));
                return [3 /*break*/, 3];
            case 2:
                error_3 = _a.sent();
                dispatch(showError(error_3));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function resetCareInsightReviewData
 * @description action creator to reset care insight review table data and pagination
 */
export var resetCareInsightReviewData = function () { return function (dispatch) {
    dispatch({ type: RESET_CARE_INSIGHT_REVIEWS });
    dispatch({ type: CARE_INSIGHT_REVIEWS_RESET_PAGINATION });
}; };
/**
 * @function declineCareInsight
 * @description action creator decline the care insight while reviewing
 * @param {string} careInsightId care insight id
 */
export var declineCareInsight = function (careInsightId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var careAgentInfo, payload, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                careAgentInfo = getCareAgentInfo();
                dispatch({ type: CARE_INSIGHT_REVIEWS_END_PAGINATION });
                payload = {
                    care_insight_ids: careInsightId,
                    updated_by: careAgentInfo.userName.first_name,
                    status: CareInsightStatus.Denied,
                };
                return [4 /*yield*/, dispatch(updateInsightStatus(payload))];
            case 1:
                _a.sent();
                dispatch(resetCareInsightReviewData());
                return [4 /*yield*/, dispatch(getAdminCareInsight(0, PAGINATION_LIMIT.adminCareInsightReview))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_4 = _a.sent();
                dispatch(showError(error_4));
                console.log(error_4);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
