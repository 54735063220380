var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
import { hideApplicationLoader, } from 'common/ApplicationLoader';
import { FETCH_LIMIT } from 'globals/global.constants';
import { getCorporateListService } from 'services/coporateAndFacilitiesService/corporateManagementService/corporateManagement.service';
import { showError } from 'store/commonReducer/common.action';
export var GET_CORPORATE_MANAGEMENT_LIST = 'GET_CORPORATE_MANAGEMENT_LIST';
export var GET_CORPORATE_MANAGEMENT_LIST_SUCCESS = 'GET_CORPORATE_MANAGEMENT_LIST_SUCCESS';
export var GET_CORPORATE_MANAGEMENT_LIST_FAIL = 'GET_CORPORATE_MANAGEMENT_LIST_FAIL';
export var UPDATE_CORPORATE_MANAGEMENT_LIST_PAGE_NUMBER = 'UPDATE_CORPORATE_MANAGEMENT_LIST_PAGE_NUMBER';
export var RESET_CORPORATE_MANAGEMENT_LIST = 'RESET_CORPORATE_MANAGEMENT_LIST';
export var GET_CORPORATE_MANAGEMENT_SEARCH_LIST_SUCCESS = 'GET_CORPORATE_MANAGEMENT_SEARCH_LIST_SUCCESS';
/**
 * @function getCorporateList
 * @description  action creator to fetch corporate data
 * @param {number} offset
 * @param {number} limit
 * @param {string} searchQuery
 */
export var getCorporateList = function (offset, limit, searchQuery) {
    if (offset === void 0) { offset = 0; }
    if (limit === void 0) { limit = FETCH_LIMIT.corporateAndFacilities; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {
                        offset: offset,
                        limit: limit,
                    };
                    dispatch({ type: GET_CORPORATE_MANAGEMENT_LIST });
                    if (searchQuery) {
                        payload.corporation_name = searchQuery;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getCorporateListService(payload)];
                case 2:
                    response = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, {
                            data: (response === null || response === void 0 ? void 0 : response.data) || [],
                        }];
                case 3:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function getCorporateListSuccess
 * @description  action creator to set corporate data on success
 */
export var getCorporateListSuccess = function (data, offset) { return function (dispatch) {
    dispatch({
        type: GET_CORPORATE_MANAGEMENT_LIST_SUCCESS,
        payload: {
            data: data,
            offset: offset,
        },
    });
}; };
/**
 * @function getCorporateSearchListSuccess
 * @description action creator to search corporate list
 * @param tableData
 */
export var getCorporateSearchListSuccess = function (data, offset) { return function (dispatch) {
    dispatch({
        type: GET_CORPORATE_MANAGEMENT_SEARCH_LIST_SUCCESS,
        payload: {
            data: data,
            offset: offset,
        },
    });
}; };
/**
 * @function getCorporateListFail
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getCorporateListFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_CORPORATE_MANAGEMENT_LIST_FAIL,
    });
}; };
/**
 * @function updateCorporatePageNumber
 * @description action creator to update the page number of corporate management table
 * @param {string | number} value
 */
export var updateCorporatePageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_CORPORATE_MANAGEMENT_LIST_PAGE_NUMBER,
        payload: value,
    });
}; };
/**
 * @function resetCorporateManagementList
 * @description action creator to reset corporate table
 */
export var resetCorporateManagementList = function () { return function (dispatch) {
    dispatch({
        type: RESET_CORPORATE_MANAGEMENT_LIST,
    });
}; };
/**
 * @description method to flter data on FE, search by name
 * @function searchByName
 * @param {any[]} data
 * @param {string} searchQuery
 * @returns {any[]}
 */
export var searchByName = function (data, searchQuery) {
    if (data === void 0) { data = []; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return data.filter(function (corporate) {
        var query = searchQuery.toLowerCase().split(' ');
        var corpName = corporate.name.toLowerCase() || '';
        return corpName.includes(query);
    });
};
