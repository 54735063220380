var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from "globals/api";
import { ASSESSMENT_TAGS_ENDPOINTS } from "globals/apiEndPoints";
import { AssessmentName, AssessmentStatuses } from "globals/enums";
/**
 * @function getUserAssessmentStatusService
 * @description API service to get Assessment Status from User Service
 * @param params api payload
 * @returns Promise
 */
export function getUserAssessmentStatusService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, data, error_1, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: ASSESSMENT_TAGS_ENDPOINTS.GET_USER_ASSESSMENT_TAGS,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _b.sent();
                    data = ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) || [];
                    return [2 /*return*/, mapAssessmentStatusPayload(data)];
                case 2:
                    error_1 = _b.sent();
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getSupervisionAssessmentStatusService
 * @description API service to get Assessment Status from Supervision Service
 * @param params api payload
 * @returns Promise
 */
export function getSupervisionAssessmentStatusService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, data, error_2, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: ASSESSMENT_TAGS_ENDPOINTS.GET_SUPERVISION_ASSESSMENT_TAGS,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _b.sent();
                    data = ((_a = response.data) === null || _a === void 0 ? void 0 : _a.data) || [];
                    return [2 /*return*/, mapAssessmentStatusPayload(data)];
                case 2:
                    error_2 = _b.sent();
                    errorMessage = error_2.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
var AssessmentNames = {
    'adl_brody_assessment': AssessmentName.LAWTON_BRODY,
    'adl_katz_assessment': AssessmentName.KATZ_INDEPENDENCE,
    'caregiver_assessment': AssessmentName.CAREGIVER_STRAIN,
    'holistic_assessment': AssessmentName.HOLISTIC,
    'wellness_survey': AssessmentName.WELLNESS_SURVEY,
    'medical_condition': AssessmentName.MEDICAL_CONDITION,
    'medication_list': AssessmentName.MEDICATION_LIST,
    'ucla_loneliness_assessment': AssessmentName.UCLA_LONELINESS,
    'fall_hospitalization_assessment': AssessmentName.FALLSANDHOSPITALIZATION,
    'home_safety_assessment': AssessmentName.HOMESAFETY,
    'member_questionnaire_1': AssessmentName.MEMBER_QUESTIONNAIRE_1,
    'member_questionnaire_2': AssessmentName.MEMBER_QUESTIONNAIRE_2,
    'member_questionnaire_3': AssessmentName.MEMBER_QUESTIONNAIRE_3,
};
function mapAssessmentStatusPayload(payload) {
    return payload.reduce(function (obj, data) {
        var _a;
        var isDisabled = false;
        if (data.status === AssessmentStatuses.NOT_ENABLED) {
            isDisabled = true;
        }
        return __assign(__assign({}, obj), (_a = {}, _a[AssessmentNames[data.name]] = {
            status: data.status,
            datetime: data.datetime,
            isDisabled: isDisabled,
        }, _a));
    }, {});
}
