var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { emptyStringToNull, getCurrentSenior } from 'globals/global.functions';
import { API } from 'globals/api';
import { getDevicesService } from 'services/deviceService/device.service';
import { openOverlayDialog, showError } from 'store/commonReducer/common.action';
import { DEVICE_TYPE, DIALOG_TYPES } from 'globals/global.constants';
export var GET_DEVICES_DATA = 'GET_DEVICES_DATA';
export var GET_DEVICES_DATA_SUCCESSFUL = 'GET_DEVICES_DATA_SUCCESSFUL';
export var GET_DEVICES_DATA_FAIL = 'GET_DEVICES_DATA_FAIL';
export var GET_WATCH_DETAILS = 'GET_WATCH_DETAILS';
export var RESET_WATCH_DETAILS = 'RESET_WATCH_DETAILS';
export var getDevicesInfo = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorInfo, param, res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                seniorInfo = getCurrentSenior();
                if (!seniorInfo) {
                    return [2 /*return*/];
                }
                param = {
                    senior_id: seniorInfo.senior_id,
                    account_id: seniorInfo.account_id,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(showApplicationLoader());
                dispatch({
                    type: GET_DEVICES_DATA,
                });
                return [4 /*yield*/, getDevicesService(param)];
            case 2:
                res = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch({
                    type: GET_DEVICES_DATA_SUCCESSFUL,
                    payload: {
                        data: res,
                    },
                });
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                dispatch({
                    type: GET_DEVICES_DATA_FAIL,
                });
                dispatch(hideApplicationLoader());
                dispatch(showError(err_1));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var saveDevicesDetail = function (data, height, weight, timezone, type) {
    return function (dispatch) {
        dispatch(showApplicationLoader());
        var accountInfo = getCurrentSenior();
        data = emptyStringToNull(data);
        var apiData = __assign(__assign({}, data), { senior_id: accountInfo.senior_id, timezone: timezone, weight: weight, height: height });
        return API({
            url: 'users/medical-device',
            method: 'put',
            data: apiData,
        })
            .then(function () {
            if (type === 'watch') {
                dispatch(connectNavigilDevice(data.device_serial));
            }
            else {
                dispatch(hideApplicationLoader());
                dispatch(openOverlayDialog({
                    type: DIALOG_TYPES.SUCCESS,
                    // eslint-disable-next-line max-len
                    firstMessage: "Device has been saved successfully",
                }));
                dispatch(getDevicesInfo());
            }
            return { success: true };
        })
            .catch(function (err) {
            dispatch(hideApplicationLoader());
            dispatch(showError(err));
        });
    };
};
export var deleteDevicesDetail = function (data, deviceName) {
    return function (dispatch) {
        var accountInfo = getCurrentSenior();
        var apiData = __assign(__assign({}, data), { senior_id: accountInfo.senior_id });
        dispatch(showApplicationLoader());
        API({
            url: 'users/medical-device',
            method: 'delete',
            data: apiData,
        })
            .then(function () {
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                // eslint-disable-next-line max-len
                firstMessage: "Device has been deleted successfully",
            }));
            dispatch({
                type: RESET_WATCH_DETAILS,
            });
            dispatch(getDevicesInfo());
            return { success: true };
        })
            .catch(function (err) {
            dispatch(hideApplicationLoader());
            dispatch(showError(err));
        });
    };
};
export var connectNavigilDevice = function (serialId) {
    return function (dispatch) {
        var accountInfo = getCurrentSenior();
        var apiData = {
            serial_id: serialId,
            device_type: DEVICE_TYPE.WATCH,
            account_id: accountInfo.account_id,
            senior_id: accountInfo.senior_id,
        };
        return API({
            url: 'users/vendor/navigil-registration',
            method: 'post',
            data: apiData,
        })
            .then(function () {
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: "Device has been saved successfully",
            }));
            dispatch(getDevicesInfo());
            return { success: true };
        })
            .catch(function (err) {
            dispatch(hideApplicationLoader());
            dispatch(showError(err));
        });
    };
};
